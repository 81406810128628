import { calculateAgeByDate } from "./getAge.js";
import { getIsDeadlinePassed } from "../db/campInfo.js";
const campDate = new Date("2025-05-28");

//Regular price is 950 with the id of 0
//Senior price is 855 with the id of 1
//Preteen (8-12) price is 665 with the id of 2
//Preschooler (4-7) price is 475 with the id of 3
//Toddler (0-3) price is 100 with the id of 4
//Stay in price is 725 with the id of 5
//Stay out price is 550 with the id of 6
//Outside DK and SE price is 885 with the id of 7
//Stay in overnight price is 475 with the id of 8
//Sunday walk in price is 75 with the id of 9
//Complimentary price is 0 with the id of 10

export const setPricingId = async (
  birthday,
  senior,
  outisdeDKSE,
  accommodation,
  codeRedeemed,
  redeemedCodePricingDetails
) => {
  if (codeRedeemed) {
    return redeemedCodePricingDetails.id; // Complimentary
  }
  const age = await calculateAgeByDate(birthday, campDate);

  if (age < 4) {
    return 4; // Toddler
  } else if (age >= 4 && age <= 7) {
    return 3; // Preschooler
  } else if (accommodation === "Stay out") {
    return 6; // Stay out
  } else if (age >= 8 && age <= 12) {
    return 2; // Preteen
  } else if (
    accommodation === "Tent" ||
    accommodation === "Bring own sleeping/matress"
  ) {
    return 5; // Stay in
  } else if (senior) {
    return 1; // Senior
  } else if (outisdeDKSE !== "DK" && outisdeDKSE !== "SE") {
    return 7; // Outside DK and SE
  } else {
    return 0; // Regular
  }
};

export const setOriginalPrice = (price) => {
  return Math.round(price * 1.053);
};
