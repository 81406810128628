//React
import { React, useState, useEffect } from "react";
import "../styles/login.css";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { Dialog } from "primereact/dialog";
import "../styles/signup.css";
import "../styles/magicLink.css";
import supabase from "../config/supabase";
import { authValid } from "../utils/auth";
import { useNavigate } from 'react-router-dom';
import { DiscordWebhookLoginDetected } from "@api/discord/discordWebhooks";
//import HCaptcha from '@hcaptcha/react-hcaptcha';
//https://supabase.com/docs/guides/auth/auth-captcha

// https://youtu.be/9bXhf_TELP4

export default function SignUp() {
  const [displaySignUp, setDisplaySignUp] = useState(true);

  const navigation = useNavigate();

  const changeDisplay = () => {
    if (displaySignUp) {
      setDisplaySignUp(false);
    } else {
      setDisplaySignUp(true);
    }
  };

  const [emailsArray, setEmailsArray] = useState([]);

  useEffect(() => {
    const checkIfUserLoggedIn = async () => {
      const loggedIn = await authValid();
      if (loggedIn) {
        window.location.href = "/profile";
        // navProfile('/profile');
      }
    };
    const listAllCurrentEmails = async () => {
      const { data, error } = await supabase.from("users").select("email");
      if (error) {
        console.log("error", error);
        return;
      } else {
        // console.log(data);
        let emailsArray = [];
        data.map((user) => {
          emailsArray.push(user.email);
        })
        setEmailsArray(emailsArray);
      }
    };
    checkIfUserLoggedIn();
    listAllCurrentEmails();
  }, []);

  const [registerEmail, setRegisterEmail] = useState("");
  const [registerPassword, setRegisterPassword] = useState("");
  const [registerConfirmPassword, setRegisterConfirmPassword] = useState("");
  const [errorEmailStyling, setErrorEmailStyling] = useState("input-group");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [errorPasswordStyling, setErrorPasswordStyling] =
    useState("input-group");
  const [errorPasswordMessage, setErrorPasswordMessage] = useState("");
  const [successMesage, setSuccessMesage] = useState("");

  const resetErrorStyling = () => {
    setErrorEmailStyling("input-group");
    setErrorPasswordStyling("input-group");
    setEmailErrorMessage("");
    setErrorPasswordMessage("");
  };

  const registerCheck = () => {
    if (registerEmail === "") {
      setErrorEmailStyling("input-group error");
      setEmailErrorMessage(
        "Email is required, how else are we going to contact you?"
      );
    } else if (registerEmail.length > 50) {
      setErrorEmailStyling("input-group error");
    } else if (emailsArray.includes(registerEmail)) {
      setErrorEmailStyling("input-group error");
      setEmailErrorMessage(
        "Email is already in use, please login instead than just try to sign up again, cause that's not how it works"
      );
      return;
    }
    if (registerPassword === "" || registerConfirmPassword === "") {
      setErrorPasswordStyling("input-group error");
      setErrorPasswordMessage(
        "Password is can't be empty, cause you know... for security reasons"
      );
    } else if (registerPassword !== registerConfirmPassword) {
      setErrorPasswordStyling("input-group error");
      setErrorPasswordMessage(
        "Passwords do not match, use the magic link if you want a passwordless option, might help if you forget your passwords often, just like right now "
      );
    } else if (registerPassword.length < 6) {
      setErrorPasswordStyling("input-group error");
      setErrorPasswordMessage(
        "Password must be at least 6 characters, don't make it easy for the hackers"
      );
    } else if (registerPassword.length > 20) {
      setErrorPasswordStyling("input-group error");
      setErrorPasswordMessage(
        "Password must be less than 20 characters, you can surely not remember that"
      );
    } else {
      setErrorEmailStyling("input-group success");
      setErrorPasswordStyling("input-group success");
      setEmailErrorMessage("");
      setErrorPasswordMessage("");
      setSuccessMesage("Success! You can now login");
      registerSubmit();
    }
  };

  const registerSubmit = async () => {
    const { data, error } = await supabase.auth.signUp({
      email: registerEmail,
      password: registerPassword,
      options: {
        emailRedirectTo: "https://camp.cbmbc.org/form",
      },
    });
    if (error) {
      console.log(error.message);
      if (
        error.message === "Unable to validate email address: invalid format"
      ) {
        setEmailErrorMessage(
          "Seems like you have entered an invalid email, can't send it to you if it's invalid, or else try Facebook login, it's a lot easier"
        );
        setErrorEmailStyling("input-group error");
      }
    } else {
      setSuccessBoxVisible(true);
    }
    if (data) {
    }
  };

  const facebookSubmit = async (e) => {
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: "facebook",
      redirectTo: "https://camp.cbmbc.org/form",
    });
    if (error) {
      console.log(error);
    }
    if (data) {
      console.log("Successfully logged in with Facebook");
    }
  };

  const googleSubmit = async (e) => {
    const { error } = await supabase.auth.signInWithOAuth({
      provider: "google",
      redirectTo: "https://camp.cbmbc.org/form",
    });
    if (error) {
      console.log(error);
    } else {
    navigation("/form");
    }
  };
  const signUpComponent = () => {
    return (
      <div className='sign-up-container'>
        <div className='sign-up-form'>
          <h1 className='container-title'>Sign up</h1>
          <div className={errorEmailStyling}>
            <label htmlFor='email'>Email</label>
            <input
              type='email'
              name='email'
              id='email'
              onChange={(event) => {
                setRegisterEmail(event.target.value);
              }}
            ></input>
            <span className='msg'>{emailErrorMessage}</span>
          </div>
          <div className={errorPasswordStyling}>
            <label htmlFor='email'>Password</label>
            <Password
              value={registerPassword}
              onChange={(e) => setRegisterPassword(e.target.value)}
              toggleMask
              feedback={false}
            />
          </div>
          <div className={errorPasswordStyling}>
            <label htmlFor='email'>Repeat password</label>
            <Password
              value={registerConfirmPassword}
              onChange={(e) => setRegisterConfirmPassword(e.target.value)}
              toggleMask
              feedback={false}
            />
            <span className='msg'>{errorPasswordMessage}</span>
          </div>
          <span className='msg'>{successMesage}</span>
          <span className='navigate-text'>
            <a onClick={() => navigation('/login')}>
              Already have an account?
            </a>
          </span>
          <Button
            label='Sign up'
            className='p-button-raised p-button-rounded'
            onClick={registerCheck}
          />
          <h2 className='sign-up-or'>OR</h2>
          <p className='hint-birthday'>
               Note: If you easliy forget your password, we recommend that you use the options below instead
              </p>
          <Button
            label='Magic Link'
            className='magicbtn p-button-raised p-button-rounded'
            icon='pi pi-envelope
                  px-1'
            onClick={()=>{
              resetErrorStyling();
              changeDisplay();
            }}
          />
          <Button
            label='Facebook'
            className='facebookbtn p-button-rounded facebook'
            icon='pi pi-facebook px-1'
            iconPos='left'
            onClick={facebookSubmit}
          ></Button>
          <Button
            label='Google'
            className='googlebtn p-button-rounded'
            icon='pi pi-google px-1'
            iconPos='left'
            onClick={googleSubmit}
          ></Button>
        </div>
      </div>
    );
  };

  /* Magic Link -------------------------------*/
  const [magicLinkEmail, setMagicLinkEmail] = useState("");

  const checkIfEmailExists = () => {
    // console.log(emailsArray);
    // console.log("magic link email", magicLinkEmail);
    if (emailsArray.includes(magicLinkEmail)) {
      setErrorEmailStyling("input-group error");
      setEmailErrorMessage("This email already exists, try logging in instead");
    } else {
      sendMagicLink();
    }
  };

  const sendMagicLink = async () => {
    const { error } = await supabase.auth.signInWithOtp({
      email: magicLinkEmail,
      options: {
        emailRedirectTo: "https://camp.cbmbc.org/form",
      },
    });

    if (error && error.message === "Unable to validate email address: invalid format") {
      setErrorEmailStyling("input-group error");
      setEmailErrorMessage("That is an invalid email. Check the spelling or go back and try Google login");
      return;
    } else if (error) {
      console.log("error", error);
      setErrorEmailStyling("input-group error");
      setEmailErrorMessage("Something went wrong, try again later");
    } else {
      resetErrorStyling();
      setErrorEmailStyling("input-group success");
      setSuccessBoxVisible(true);
    }
  };

  const magicLinkComponent = () => {
    return (
      <div className='sign-up-container'>
        <div className='sign-up-form'>
          <h1 className='sign-up-title'>Magic link</h1>
          <p className='ptext-magic-link'>
            Magic links are a type of passwordless login that allow users to log
            into an account by clicking a link that's emailed to them, rather
            than typing in their username and password. It's also more secure and recommended for users who easily forget their password.
          </p>
          <div className={errorEmailStyling}>
            <label htmlFor='email'>Email</label>
            <input
              type='email'
              name='email'
              id='email'
              onChange={(event) => {
                setMagicLinkEmail(event.target.value);
              }}
            ></input>
            <span className='msg'>{emailErrorMessage}</span>
          </div>
          <Button
            label='Send magic link'
            icon='pi pi-send'
            iconPos='left'
            className='magicbtn p-button-raised p-button-rounded'
            onClick={checkIfEmailExists}
          ></Button>
          <Button
            label='Go back'
            icon='pi pi-angle-left'
            onClick={()=>{
              resetErrorStyling();
              changeDisplay()}}
            className='backbtn p-button-rounded'
          />
        </div>
      </div>
    );
  };

  const [successBoxVisible, setSuccessBoxVisible] = useState(false);

  return (
    <>
      <div className='card flex justify-content-center'>
        <Dialog
          header='Successfully created an account '
          visible={successBoxVisible}
          onHide={() => setSuccessBoxVisible(false)}
          style={{ width: "50vw" }}
          breakpoints={{ "960px": "75vw", "641px": "100vw" }}
        >
          <p className='m-0' style={{ marginBottom: "20px" }}>
            An email has been sent to you, please check your inbox and click on
            the link to finish the registration process.
          </p>
        </Dialog>
      </div>
      {displaySignUp ? signUpComponent() : magicLinkComponent()}
    </>
  );
}
