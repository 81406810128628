import { useState } from "react";
import { SelectButton } from "primereact/selectbutton";

export default function SelectMembership({ onSelectChange, addCamperPage }) {
  const [membership, setMembership] = useState("");

  const labelString = addCamperPage ? "they are" : "I am";

  const memberOrGuest = [
    { label: `Yes ${labelString} a Member 😊`, value: true },
    { label: `No ${labelString} Guest 😁`, value: false },
  ];

  const handleSelectChange = (e) => {
    setMembership(e.value);
    onSelectChange(e.value); // Call the callback function with the selected value
  };

  const subject = addCamperPage ? "they" : "you";

  return (
    <div className='center'>
      <p className='member-boolean-txt'>
        Are {subject} a scripturally baptized member of CBMBC and her Missions
        ?
      </p>
      <SelectButton
        value={membership}
        options={memberOrGuest}
        onChange={handleSelectChange}
        className='center-selection'
      />
    </div>
  );
}
