import React from "react";

export default function PrivacyPolicy() {
  return (
    <section className='page-text-white bg-black-gradient-grid-2 page-container'>
      <div className='text-left box-width page-box'>
        <h1>Privacy Policy</h1>
        <p className='subtitle'>Last updated: February 12, 2025</p>
        <h2>Introduction</h2>
        <p>
          We are committed to protecting your personal data and respecting your
          privacy. This Privacy Policy explains how we collect, use, and
          safeguard your information when you register for our camp.
        </p>

        <h2>Information We Collect</h2>
        <p>
          To facilitate your registration and participation in the camp, we
          collect the following personal data:
        </p>
        <ul>
          <li>First and last name</li>
          <li>Email address</li>
          <li>Date of birth</li>
          <li>Country of residence</li>
          <li>Church mission affiliation</li>
          <li>Membership status with our church</li>
          <li>Camp attendance dates</li>
          <li>T-shirt size (to provide appropriate apparel)</li>
          <li>Camp transportation details (arrival and departure)</li>
          <li>Accommodation preference (e.g., room or tent)</li>
          <li> Skill level in various categories as welll as requestes constraints for the group formation algorithm</li>
        </ul>

        <h2>Purpose of Data Collection</h2>
        <p>The information we collect is essential for:</p>
        <ul>
          <li>Identifying and registering you for the camp</li>
          <li>
            Determining the appropriate camp fee based on the provided data
          </li>
          <li>Ensuring suitable accommodation</li>
          <li>Communicating important camp-related information</li>
        </ul>

        <h2>Data Storage and Security</h2>
        <p>
          Your personal data is securely stored in our database located in
          Frankfurt, Germany. We implement appropriate technical and
          organizational measures to protect your data against unauthorized
          access, alteration, disclosure, or destruction.
        </p>

        <h2>Data Sharing and Disclosure</h2>
        <p>
          We do not share your personal data with third parties. Access to your
          information is restricted to the church camp committee and{" "}
          <a href='/group-formation'>The Group Formation Algorithm Team.</a>{" "}
          Your email address will be used solely for sending receipts and
          relevant camp information and will not be used for any other purposes.
        </p>

        <h2>GDPR Compliance & Your Rights</h2>
        <p>
          We adhere to the General Data Protection Regulation (GDPR) and respect
          your rights regarding your personal data. You have the right to:
        </p>
        <ul>
          <li>Access the personal data we hold about you</li>
          <li>Request correction of any inaccurate or incomplete data</li>
          <li>
            Request deletion of your personal data under certain circumstances
          </li>
          <li>Object to or request restriction of processing your data</li>
          <li>
            Withdraw consent at any time, where processing is based on consent
          </li>
        </ul>

        <h3>Important Notice:</h3>
        <p className='margin-top-0'>
          Modifying or deleting your data can only be done after the end of the
          camp. Making changes or requesting deletion during the registration
          and preparation process may cause significant disruptions and impact
          our ability to organize the camp efficiently. Your data is essential
          to ensuring a smooth registration experience and proper team
          assignments.
        </p>

        <p>
          To exercise this right, please contact us at camp.cbmbc@gmail.com.{" "}
        </p>

        <h2>Data Retention</h2>
        <p>
          We will retain your personal data only for as long as necessary to
          fulfill the purposes outlined in this Privacy Policy and to comply
          with legal obligations.
        </p>
        <p>
          We will use your data to analyze and improve future church camps. If
          you wish to have your data removed after the camp, you have the right
          to request deletion.
        </p>
        <p>
          Your email and login details will be securely stored unless you
          request their removal. These credentials will be used exclusively for
          camp-related purposes and will not be used for any other activities or
          shared with third parties.
        </p>

        <h2>Changes to This Privacy Policy</h2>
        <p>
          We may update this Privacy Policy so any changes will be posted on
          this page
        </p>

        <h2>Contact Us</h2>
        <p>
          If you have any questions or concerns about this Privacy Policy or our
          data practices, please contact us at camp.cbmbc@gmail.com.
        </p>
      </div>
    </section>
  );
}
