import { useEffect, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import CampersList from "@components/campers/CampersList";
import CampData from "@components/campers/CampData";
import { getActiveIndexTab, setActiveIndexTab } from "@utils/activeTab";
import RoomsDisplay from "@components/display/RoomsDisplay.js";
import TemasDisplay from "../components/display/TeamsDisplay.js";
import { getAdminSettings } from "@db/admin.js";
import Section from "@components/Section.js";
import "@styles/primereact.css";
import { Image } from "primereact/image";
import "@styles/image.css";

export default function Campers() {
  const [activeIndex, setActiveIndex] = useState(0);
  const [settings, setSettings] = useState({
    SHOW_ROOMS: { bool: false },
    SHOW_TEAMS: { bool: false },
  });

  useEffect(() => {
    getActiveIndexTab("campersActiveIndex", setActiveIndex);

    const fetchSettings = async () => {
      const settings = await getAdminSettings(["SHOW_ROOMS", "SHOW_TEAMS"]);
      setSettings(settings);
      console.log("s",settings.SHOW_ROOMS.bool)
    };

    fetchSettings();
  }, []);
  return (
    <>
      <TabView
        activeIndex={activeIndex}
        onTabChange={(e) => {
          setActiveIndexTab("campersActiveIndex", e.index);
          setActiveIndex(e.index);
        }}
      >
        <TabPanel header='Campers'>
          <CampersList />
        </TabPanel>
        <TabPanel header='Data'>
          <CampData />
        </TabPanel>
        <TabPanel header='Teams'>
          {settings.SHOW_TEAMS.bool ? (
            <TemasDisplay />
          ) : (
            null
            // <Section subtitle='Teams are not available yet ' color='black'>
            //   <Image
            //     src='https://images.squarespace-cdn.com/content/v1/53ee3064e4b05a8690c4fbcd/1568839643495-D11V6KR4864F03Q47N2I/OneDayBuildSite2_Loop.gif'
            //     alt='small-people-building-website-gif'
            //     width='400px'
            //     className='box-center-grid image-responsive'
            //     />
            // </Section>
          )}
        </TabPanel>
        <TabPanel header='Rooms'>
          {settings.SHOW_ROOMS.bool ? (
            <RoomsDisplay />
            ) : (
              <Section
              subtitle='Room assignments are not available yet'
              color='black'
              >
                <Image
                src='https://images.squarespace-cdn.com/content/v1/53ee3064e4b05a8690c4fbcd/1568839643495-D11V6KR4864F03Q47N2I/OneDayBuildSite2_Loop.gif'
                alt='small-people-building-website-gif'
                width='400px'
                className='box-center-grid image-responsive'
                />
              </Section>
              )}
        </TabPanel>
      </TabView>
    </>
  );
}
