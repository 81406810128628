import React from "react";
import { useState, useEffect } from "react";
import { AutoComplete } from "primereact/autocomplete";
import { Checkbox } from "primereact/checkbox";
import { getAllUsersParams } from "@db/users";
import { Dropdown } from "primereact/dropdown";

let selectedCamper = null;

export function OtherCamper({
  onChangerOtherCamper,
  stayAnoymous,
  setStayAnoymous,
  currentUserId,
}) {
  const [items, setItems] = useState([]); // Add this line
  const [selectCamperState, setSelectCamperState] = useState(null);
  const [selectedCampersName, setSelectedCampersName] = useState(null);
  const [selectCamperObject, setSelectCamperObject] = useState({
    id: null,
    name: null,
    email: null,
    price_name: null,
    price: null,
  });

  const [campListAutoComplete, setCampListAutoComplete] = useState([]);
  const uuidSet = new Set();
  const nameSet = new Set();

  const handleSelect = (e) => {
    // setSelectValue(e.name);
    setSelectCamperState(e.value);
    selectedCamper = e.value;
  };

  useEffect(() => {
    selectedCamper = selectCamperState;
    if (selectCamperState === null) return;
    setSelectedCampersName(selectCamperState.name);
    onChangerOtherCamper();
  }, [selectCamperState]);

  const setData = (data) => {
    const newCampersList = data
      .map((camper) => {
        if (camper.id === currentUserId) return null;
        if (!uuidSet.has(camper.id)) {
          uuidSet.add(camper.id);

          let name = camper.first_name + " " + camper.last_name;
          if(!camper.is_attending) name += " (Not Attending)";
          else if (camper.is_registered) name += " (Registered)";

          return {
            name: name,
            value: {
              name: camper.first_name + " " + camper.last_name,
              id: camper.id,
              pricingId: camper.pricing_id,
            },
            disabled: camper.is_registered || !camper.is_attending,
          };
        }
        return null;
      })
      .filter((camper) => camper !== null);

    // Sort the newCampersList alphabetically by the 'name' property
    newCampersList.sort((a, b) => {
      if (a.disabled && !b.disabled) {
        return 1; // Move unregistered campers to the beginning
      } else if (!a.disabled && b.disabled) {
        return -1; // Move registered campers to the end
      } else {
        return a.name.localeCompare(b.name); // Sort alphabetically
      }
    });
    setCampListAutoComplete((prev) => [...prev, ...newCampersList]);
  };

  const insertNameSet = (data) => {
    data.forEach((camper) => {
      nameSet.add(camper.first_name + "" + camper.last_name);
    });
  };

  useEffect(() => {
    const fetchCampersData = async () => {
      const data = await getAllUsersParams(
        "id, first_name, last_name, email, is_registered, pricing_id, is_attending"
      );
      if (data === null) {
        console.log("There was an error");
      } else {
        setData(data);
        insertNameSet(data);
      }
    };
    fetchCampersData();
  }, []);

  return (
    <>
      <Dropdown
        value={selectCamperState}
        onChange={(e) => handleSelect(e)}
        options={campListAutoComplete}
        optionLabel='name'
        placeholder='Select a person'
        filter
        className='w-full md:w-14rem'
        optionDisabled='disabled'
        emptyFilterMessage='No campers found'
        style={{ width: "100%" }}
      />
      <div className='align-horizontal-anonymous'>
        <Checkbox
          checked={stayAnoymous}
          onChange={(e) => setStayAnoymous(e.checked)}
        />{" "}
        Stay Anonymous
      </div>
    </>
  );
}

export function getSelectedCamper() {
  return selectedCamper;
}
