import React, { useState } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { discountCodePricingDetails } from "@/db/discountCode.js";

export default function RedeemCode({ onSelectChange, onSelectCodeDetails }) {

  const [codeValue, setCodeValue] = useState("");
  const [buttonLabel, setButtonLabel] = useState("Redeem");
  const [buttonStyle, setButtonStyle] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const redeemCode = async () => {
    setLoading(true);
    setButtonLabel("Verifying...");
    setButtonStyle("loading-button");
    setIsDisabled(true);

    setTimeout(async () => {
      const isValidLength = codeValue.length === 7;
    
      if (!isValidLength) {
        setButtonStyle("error-button");
        setButtonLabel("Invalid code");
        setIsDisabled(false);
        setLoading(false);
        return;
      }
      const codeDetails = await getCodeDetails(codeValue);
    
      if (!codeDetails) {
        setButtonStyle("error-button");
        setButtonLabel("Invalid code");
        setIsDisabled(false);
      } else {
        setButtonStyle("success-button");
        setButtonLabel("Code redeemed");
        setIsDisabled(true);
        onSelectChange(true);
        setCodeValue("Success 🎉");
        onSelectCodeDetails(codeDetails);
      }
    
      setLoading(false);
    }, 4000);
  };

  const getCodeDetails = async (input) => {
    return await discountCodePricingDetails(input);
  };

  return (
    <>
      <div className='center center-selection'>
        <InputText
          disabled={isDisabled}
          value={codeValue}
          onChange={(e) => setCodeValue(e.target.value)}
          placeholder='Promo code'
        />
        <Button
          disabled={isDisabled}
          label={buttonLabel}
          severity='pi success'
          onClick={redeemCode}
          className={buttonStyle}
          loading={loading}
        />
      </div>
    </>
  );
}