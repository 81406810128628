import supabase from "@config/supabase.js";

export const resetAllUsersRegistrationAndPayments = async () => {
  const { error } = await supabase
    .from("users")
    .update({
      is_registered: false,
      registered_at: null,
    })
    .neq("first_name", "admin");

  const { error: error2 } = await supabase
    .from("users")
    .update({
      has_bed: false,
    })
    .eq("has_bed", true);

  const { error: error3 } = await supabase.from("payments")
  .delete()
  .neq("id", "caada6e2-6f65-4a53-8dc5-bd5716b41722");

  if (error || error2 || error3) {
    console.error(error);
    console.error(error2)
    console.error(error3);
    return false;
  }
  return true;
};
