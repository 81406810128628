import React, { useRef, useState } from "react";
import { useInView } from "react-intersection-observer";
import "../styles/about.css";

export default function About() {
  return (
    <section className='page-text-white bg-green-blue-gradient bg-rotate page-container'>
      <h1 className='text-h1'>About</h1>

      <div className='text-left box-width page-box'>
        <p>
          We extend a warm welcome to all campers joining us from various
          missions and guests from around the world. It is a joyous occasion to
          come together as a family united in faith. As we come together we
          expect a time of fellowship, spiritual growth, and cherished memories.
          Throughout this camp, we aim to develop a space where love, kindness,
          and Christian values grow. May this time be one of deepening
          connections with each other and where revival of our spirits and
          commitments to our Lord and Savior Jesus Christ.
        </p>
        <p>
          Welcome to the Church Family Camp 2025. Enjoy the beautiful
          surroundings, the uplifting worship, message and the fun fellowship
          and may God's blessings be with you throughout this camp and beyond.
        </p>
        <h2>Worship Service</h2>
        <p>
          All throughout the camp, we have our worship service with our very own
          Speaker, Pastor Carroll Williams. During this time, we hear a great
          message taken from our key verse. Through this, we will hear a gospel
          that helps us grow in spirit and understand more the true meaning of
          our theme "Rooted in Christ, Growing in Grace." So, we encourage you to bring your
          pen, notes, and Bible with you.
        </p>
        <h2>Outdoor/Indoor Games</h2>
        <p>
          At our Church Camp, the Outdoor/Indoor Games offers a variety of fun
          activities where you'll compete in colorful challenges and get to know
          other campers better. It's a chance to have a great time, make new
          friends, and enjoy games both indoors and outdoors! Whether you're
          playing outside or inside, there's something for everyone to enjoy and
          bond over.
        </p>
        <h2>Drama</h2>

        <p>
          By joining the camp, you have been carefully selected for teams that
          you will work with throughout the camp, with one leader accompanied by
          team members. Two weeks before the camp, your leader will be given a
          Bible story, and you as a team will discuss that together. Each team
          will perform during the days. Don't worry, you will be given time to
          practice. Bible story dramas are a creative and engaging way to bring
          the stories of the Bible to life. They involve acting out scenes from
          the Bible with costumes, props, and sometimes even simple sets. These
          dramas help to make the stories more vivid and memorable, allowing
          campers to connect with the characters and the story itself.
        </p>
        <h2>Bonfire</h2>
        <p>
          Bonfires have been a timeless tradition in the camp that bring people
          together under the flickering light of dancing flames. They create a
          warm and inviting atmosphere, perfect for sharing testimonies, singing
          worship songs, as people huddle around the fire, toasting
          marshmallows, and simply enjoying the company of friends and family.
        </p>

        <h2>Kids ministry</h2>
        <p>
          We have a kids ministry that will be running throughout the camp. The
          kids will be learning about the Bible in a fun and engaging way. They
          will also be doing crafts and playing games. We have a team of
          dedicated volunteers who are excited to be working with the kids.
        </p>
      </div>
    </section>
  );
}
/*
Activities

Worship Service:

All throughout the camp, we have our worship service with our very own Speaker, Pastor Caroll Williams. During this time, we hear a great message taken from our key verse. Through this, we will hear a gospel that helps us grow in spirit and understand more the true meaning of our theme "Striveth for Mastery." So, we encourage you to bring your pen, notes, and Bible with you.

Outdoor/Indoor Games:

Games are a fun way to pass the time, challenge our minds, and bring people together. Through this, we create camaraderie and build Christian character.

Bonfires:

Bonfires have been a timeless tradition in the camp that bring people together under the flickering light of dancing flames. They create a warm and inviting atmosphere, perfect for sharing testimonies, singing worship songs, as people huddle around the fire, toasting marshmallows, and simply enjoying the company of friends and family.

Drama:

By joining the camp, you have been carefully selected for teams that you will work with throughout the camp, with one leader accompanied by team members. Two weeks before the camp, your leader will be given a Bible story, and you as a team will discuss that together. Each team will perform during the days. Don’t worry, you will be given time to practice.

Bible story dramas are a creative and engaging way to bring the stories of the Bible to life. They involve acting out scenes from the Bible with costumes, props, and sometimes even simple sets. These dramas help to make the stories more vivid and memorable, allowing campers to connect with the characters and the story itself.
*/
