import supabase from "../config/supabase.js";

export const discountCodePricingDetails = async (input) => {
  const { data, error } = await supabase
    .from("discounts")
    .select("pricing_code (id, code, price)")
    .eq("code", input)
    .limit(1)
    .maybeSingle();
    
  if (error) {
    console.log("error in validateCode");
    console.log(error);
    return null;
  } else {
    return data.pricing_code;
  }
};
