import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TabView, TabPanel } from "primereact/tabview";
import "../styles/profile.css";
import supabase from "../config/supabase";
import AddCamperTab from "../components/tabs/AddCamper";
import "../styles/button.css";
import ProfileInfo from "../components/tabs/ProfileInfo";
import PaymentInfo from "../components/tabs/PaymentInfo.js";
import ProfileSettings from "../components/tabs/ProfileSettings.js";
import { getActiveIndexTab, setActiveIndexTab } from "@utils/activeTab";

export default function Profile(active) {

  const [currentUsersData, setCurrentUsersData] = useState([]);

  const [activeIndex, setActiveIndex] = useState(null);
  const navHome = useNavigate();

  useEffect(() => {
    const getUuid = async () => {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (user === null) return navHome("/");
      getUserData(user.id);
    };
    getUuid();

    getActiveIndexTab("activetab", setActiveIndex);
  }, []);

  const getUserData = async (uuid) => {
    const { data, error } = await supabase
      .from("users")
      .select()
      .eq("id", uuid);
    if (error) {
      console.log("error", error);
      return;
    }
    if (data === null || data.length === 0) {
      navHome("/form");
    } else {

      setCurrentUsersData(data[0]);
    }
  };

  // const [visible, setVisible] = useState(false);
  // const footerContent = (
  //   <div>
  //     <Button
  //       label='No'
  //       icon='pi pi-times'
  //       onClick={() => setVisible(false)}
  //       className='p-button-text'
  //     />
  //     <Button
  //       label='Yes'
  //       icon='pi pi-check'
  //       onClick={() => setVisible(false)}
  //       autoFocus
  //     />
  //   </div>
  // );

  return (
    <>
      <div className='profile-container'>
        <TabView
          className='profile-center'
          activeIndex={activeIndex}
          onTabChange={(e) => {
            setActiveIndexTab("activetab", e.index);
            setActiveIndex(e.index);
          }}
        >
          <TabPanel header='Profile'>
            <ProfileInfo />
          </TabPanel>
          <TabPanel header='Payment' className='profile-payment-tab'>
            <PaymentInfo currentUsersData={currentUsersData} />
          </TabPanel>
          <TabPanel header='Add camper'>
              <AddCamperTab />
          </TabPanel>
          {/* <TabPanel header='My Team' leftIcon='pi pi-users'>
            <TeamInfo />
            <p>You are not assign in any team at this moment</p>
          </TabPanel>
          <TabPanel header='Room' leftIcon='pi pi-home'>
            <RoomsInfo />
          </TabPanel> */}
          <TabPanel header='Settings'>
            <ProfileSettings />
          </TabPanel>
        </TabView>
      </div>
    </>
  );
}
