import supabase from "../config/supabase.js";
import { deleteAllPayments } from "./payments.js";
import { getCampInfoTotalBeds } from "./campInfo.js";

const totalRoom = async () => {
  return await getCampInfoTotalBeds();
};
export const getAllUsersParams = async (selectParams) => {
  const { data, error } = await supabase.from("users").select(selectParams);
  if (error) {
    console.log("An error occured: getAllUsersParams");
    console.log(error);
    return;
  }
  return data;
};

export const getAllUsers = async () => {
  const { data, error } = await supabase
    .from("users")
    .select(
      "id,first_name,last_name,birthday,country,is_registered,registered_at,accommodation, gender, email, church_mission, size, arrival_transportation, departure_transportation, phone, parent:parent_id (first_name, last_name), pricing (name, price), team_id (name), room_id (name)"
    );
  if (error) {
    console.log("An error occured: getAllUsers");
    console.log(error);
    return;
  }
  return data;
};

export const insertUser = async (user) => {
  const { data, error } = await supabase.from("users").insert(user);
  if (error) {
    console.log("An error occured: insertUser");
    console.log(error);
    return { error: error, statusCode: 400 };
  }
  return { data: data, statusCode: 200 };
};

export const setUsersRegistration = async (userId, registeredAt) => {
  const { data, error } = await supabase
    .from("users")
    .update({ registered_at: registeredAt, is_registered: true })
    .eq("id", userId);
  if (error) {
    console.log("An error occured: setUsersRegistration");
    console.log(error);
    return;
  }
  if (await checkIfUSerTookTheLastRoom()) {
    await setUsersNotRegisteredRoomsToStayIn();
  }
  return data;
};

export const checkIfUserIsRegistered = async (userId) => {
  const { data, error } = await supabase
    .from("users")
    .select("is_registered")
    .eq("id", userId)
    .limit(1)
    .single();
  if (error) {
    console.log("An error occured: checkIfUserIsRegistered");
    console.log(error);
    return;
  }
  return data.is_registered;
};

export const getFullNameFromUserById = async (uuid) => {
  const { data, error } = await supabase
    .from("users")
    .select("first_name, last_name")
    .eq("id", uuid)
    .limit(1)
    .single();
  if (error) {
    console.log("An error occured: getFullNameFromUserById");
    console.log(error);
    return;
  }
  return data.first_name + " " + data.last_name;
};

const checkIfUSerTookTheLastRoom = async () => {
  const { data, error } = await supabase
    .from("users")
    .select("id")
    .in("pricing_id", [0, 1, 2, 7, 10])
    .eq("accommodation", "Room")
    .eq("is_registered", true);
  if (error) {
    console.log("An error occured: checkIfUSerTookTheLastRoom");
    console.log(error);
    return;
  }

  return data.length === totalRoom;
};

export const getPriceAmountFromUser = async (uuid) => {
  const { data, error } = await supabase
    .from("users")
    .select("pricing (price")
    .eq("id", uuid)
    .limit(1)
    .single();
  if (error) {
    console.log("An error occured: getPriceAmountFromUser");
    console.log(error);
    return;
  }
  return data.pricing.price;
};

const usersWhoHasRoomAndNotRegistered = async () => {
  const { data, error } = await supabase
    .from("users")
    .select("id")
    .in("pricing_id", [0, 1, 7])
    .eq("accommodation", "Room")
    .eq("is_registered", false);

  if (error) {
    console.log("An error occured: usersWhoHasRoomAndNotRegistered");
    console.log(error);
    return;
  }
  const dataList = data.map((u) => ({
    id: u.id,
    pricing_id: 5,
    accommodation: "Stay in",
  }));
  return dataList;
};

export const setUsersNotRegisteredRoomsToStayIn = async () => {
  const dataUpdate = await usersWhoHasRoomAndNotRegistered();
  const { data, error } = await supabase
    .from("users")
    .upsert(dataUpdate)
    .select();

  if (error) {
    console.log("An error occured: setUsersNotRegisteredRoomsToStayIn");
    console.error("Error updating users:", error.message);
    return null;
  }

  return data;
};

export const getUserInfoForEmail = async (uuid) => {
  const { data, error } = await supabase
    .from("users")
    .select(
      "first_name, last_name, email, accommodation, pricing (id, name, price)"
    )
    .eq("id", uuid)
    .limit(1)
    .single();
  if (error) {
    console.log("An error occured: getUserInfoForEmail");
    console.log(error);
    return;
  }

  data.camperNumber = await getCamperNumberById(uuid);

  return data;
};

export const resetAllUsersRegistrationAndPayments = async () => {
  const users = await getUsersWhoIsRegistered();

  await deleteAllPayments();

  if (users.length === 0) return;

  const dataUpdate = users.map((u) => ({
    id: u.id,
    is_registered: false,
    registered_at: null,
  }));

  const { data, error } = await supabase
    .from("users")
    .upsert(dataUpdate)
    .select();
  if (error) {
    console.log("An error occured: resetAllUsersRegistration");
    console.log(error);
    return;
  }
  return data;
};

const getUsersWhoIsRegistered = async () => {
  const { data, error } = await supabase
    .from("users")
    .select("id")
    .eq("is_registered", true);
  if (error) {
    console.log("An error occured: getUsersWhoIsRegistered");
    console.log(error);
    return;
  }

  return data;
};

export const getUserForWebhookRegistration = async (uuid) => {
  const { data, error } = await supabase
    .from("users")
    .select(
      "first_name, last_name, birthday, registered_at, accommodation ,pricing (name, price) "
    )
    .eq("id", uuid)
    .limit(1)
    .single();
  if (error) {
    console.log("An error occured: getUserForWebhookRegistration");
    console.log(error);
    return;
  }

  data.camperNumber = await getCamperNumberOfUser(data.registered_at);

  return data;
};

export const getCamperNumberById = async (id) => {
  const { data, error } = await supabase
    .from("users")
    .select("registered_at")
    .eq("id", id)
    .limit(1)
    .single();

  if (error) {
    console.log("An error occured: getCamperNumberById");
    console.log(error);
    return null;
  }

  return await getCamperNumberOfUser(data.registered_at);
};

const getCamperNumberOfUser = async (registered_at) => {
  const { data, error } = await supabase
    .from("users")
    .select("registered_at")
    .lte("registered_at", registered_at);
  if (error) {
    console.log("An error occured: getCamperNumberOfUser");
    console.log(error);
    return;
  }
  if (data.length == undefined) return 1;
  return data.length;
};

export const getTotalPriceAmountOfAllUsers = async () => {
  const { data, error } = await supabase
    .from("users")
    .select("pricing (price)")
    .eq("is_attending", true);
  if (error) {
    console.log("An error occured: getTotalPriceAmountOfAllUsers");
    console.log(error);
    return;
  }

  return data.reduce((acc, user) => acc + user.pricing.price, 0);
};

export const getTotalPriceAmountOfUsersByRegistrationStatus = async (
  isRegistered
) => {
  const { data, error } = await supabase
    .from("users")
    .select("pricing (price)")
    .eq("is_registered", isRegistered);

  if (error) {
    console.error(
      `An error occurred: getTotalPriceAmountOfUsersByRegistrationStatus (isRegistered: ${isRegistered})`
    );
    console.error(error);
    return;
  }

  return data.reduce((acc, user) => acc + user.pricing.price, 0);
};

export const countUsersByRegistrationStatus = async (registered) => {
  const { count, error } = await supabase
    .from("users")
    .select("id", { count: "exact" }) // Request the count
    .eq("is_registered", registered);

  if (error) {
    console.error(
      `An error occurred: countUsersByRegistrationStatus (registered: ${registered})`
    );
    console.error(error);
    return;
  }

  return count; // Return the count of users
};

export const getTransportationInfo = async () => {
  const { data, error } = await supabase
    .from("users")
    .select(
      "first_name, last_name, arrival_transportation, departure_transportation"
    )
    .eq("is_registered", true);
  if (error) {
    console.log("An error occured: getTransportationInfo");
    console.log(error);
    return;
  }

  const transportationData = {
    arrival: {
      car: {
        total: data.filter((u) => u.arrival_transportation === "Car").length,
        names: data
          .filter((u) => u.arrival_transportation === "Car")
          .map((u) => u.first_name + " " + u.last_name),
      },
      bus: {
        total: data.filter((u) => u.arrival_transportation === "Bus").length,
        names: data
          .filter((u) => u.arrival_transportation === "Bus")
          .map((u) => u.first_name + " " + u.last_name),
      },
      public_transport: {
        total: data.filter(
          (u) => u.arrival_transportation === "Public transportation"
        ).length,
        names: data
          .filter((u) => u.arrival_transportation === "Public transportation")
          .map((u) => u.first_name + " " + u.last_name),
      },
    },
    departure: {
      car: {
        total: data.filter((u) => u.departure_transportation === "Car").length,
        names: data
          .filter((u) => u.departure_transportation === "Car")
          .map((u) => u.first_name + " " + u.last_name),
      },
      bus: {
        total: data.filter((u) => u.departure_transportation === "Bus").length,
        names: data
          .filter((u) => u.departure_transportation === "Bus")
          .map((u) => u.first_name + " " + u.last_name),
      },
      public_transport: {
        total: data.filter(
          (u) => u.departure_transportation === "Public transportation"
        ).length,
        names: data
          .filter((u) => u.departure_transportation === "Public transportation")
          .map((u) => u.first_name + " " + u.last_name),
      },
    },
  };
  return transportationData;
};

export const getCountriesInfo = async () => {
  const { data, error } = await supabase.from("users").select("country");

  if (error) {
    console.log("An error occurred: getCountriesInfo");
    console.log(error);
    return;
  }

  const countries = data.map((u) => u.country);
  const uniqueCountries = [...new Set(countries)];

  let countryData = uniqueCountries.map((country) => {
    return {
      name: country,
      total: countries.filter((c) => c === country).length,
    };
  });

  // Sort the countryData array in descending order based on the total property
  countryData = countryData.sort((a, b) => b.total - a.total);

  return countryData;
};

export const getChurchMissionInfo = async () => {
  const { data, error } = await supabase.from("users").select("church_mission");

  if (error) {
    console.log("An error occurred: getChurchMissionInfo");
    console.log(error);
    return;
  }

  const churchMissions = data.map((u) => u.church_mission);
  const uniqueChurchMissions = [...new Set(churchMissions)];

  let churchMissionData = uniqueChurchMissions.map((churchMission) => {
    return {
      name: churchMission ? churchMission : "None",
      total: churchMissions.filter((c) => c === churchMission).length,
    };
  });

  // Sort the churchMissionData array in descending order based on the total property
  churchMissionData = churchMissionData.sort((a, b) => b.total - a.total);

  return churchMissionData;
};
