import { useState } from "react";
import { InputMask } from "primereact/inputmask";
import { formatLocalDateUS } from '../../utils/dateTime.js';

export default function SelectBirthday({ onSelectChange }) {
  const [isValidDate, setIsValidDate] = useState(true);
  const [displayDate, setDisplayDate] = useState("");

  const handleBlur = (e) => {
    const date = new Date(e.target.value);

    if (!isNaN(date.getTime())) {
      setIsValidDate(true); // Set validity to true if the entered date is valid
      setDisplayDate(formatLocalDateUS(date)); // Set the display date to the formatted date
    } else {
      setIsValidDate(false); // Set validity to false if the entered date is invalid
      setDisplayDate(""); // Clear the display date
    }
  };

  const handleChange = (e) => {
    const date = new Date(e.value);

    let adjustedDate;
    if (!isNaN(date.getTime())) {
      // Adjust for timezone offset
      adjustedDate = new Date(
        date.getTime() - date.getTimezoneOffset() * 60000
      );
      onSelectChange(adjustedDate);
    }
  };

  return (
    <>
      <InputMask
        onBlur={handleBlur}
        onChange={handleChange}
        mask="9999/99/99"
        placeholder="Birthday YYYY/MM/DD"
        slotChar="YYYY/MM/DD"
        size={10}
        required
        className={!isValidDate ? "p-invalid" : ""}
      />
      {isValidDate ? (
        <p className="checkbox-text">{displayDate}</p>
      ) : (
        <p className="error-msg">Please enter a valid date</p>
      )}
    </>
  );
}
