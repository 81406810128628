export const DeveloperMode = () => {
  // return false
  const url = window.location.href;
  if (
    url.includes("localhost") ||
    url.includes("vercel.app") ||
    url.includes("euw.devtunnels.ms")
  )
    return true;
  else if (url.includes("camp.cbmbc.org")) return false;
  else return false;
};
