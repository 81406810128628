export const formatLocalDateTime = (utcTimeString) => {
  // Convert the UTC time string to a Date object in local time
  const date = new Date(utcTimeString);

  // Format the date components
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth() is zero-based
  const day = String(date.getDate()).padStart(2, "0");

  // Format the time components
  const hour = String(date.getHours()).padStart(2, "0");
  const minute = String(date.getMinutes()).padStart(2, "0");

  // Combine components into the desired format
  return `${year}/${month}/${day} ${hour}:${minute}`;
};

export const formatLocalDateUS = (dateInput) => {
  let date;
  if (typeof dateInput === 'string') {
    // Parse the string as a UTC date
    const [year, month, day] = dateInput.split('-').map(num => parseInt(num, 10));
    date = new Date(Date.UTC(year, month - 1, day));
  } else if (dateInput instanceof Date) {
    // Create a UTC date using the provided Date's components
    date = new Date(Date.UTC(dateInput.getFullYear(), dateInput.getMonth(), dateInput.getDate()));
  } else {
    console.error('Invalid date input');
    return 'Invalid date';
  }

  // Format the UTC date as 'en-US' without converting it to the local time zone
  return new Intl.DateTimeFormat("en-US", {
    timeZone: 'UTC', // Ensure the formatted date is in UTC
    month: "long",
    day: "numeric",
    year: "numeric",
  }).format(date);
};

export const formatDateToday = (c) => {
  // Create a new Date object for the current date and time
  if (c === null || c === undefined) c = '-'
const now = new Date();

// Extract day, month, year, and time components
const day = String(now.getDate()).padStart(2, '0'); // Pad with zero if necessary to ensure two digits
const month = String(now.getMonth() + 1).padStart(2, '0'); // Month is 0-indexed, add 1 to get the correct month
const year = now.getFullYear();

// Extract time components and format as HH_MM_SS
const hours = String(now.getHours()).padStart(2, '0');
const minutes = String(now.getMinutes()).padStart(2, '0');
const seconds = String(now.getSeconds()).padStart(2, '0');
const time = `${hours}_${minutes}_${seconds}`;

// Combine all components with underscores
const formattedDate = `${time}${c}${day}${c}${month}${c}${year}`;

return formattedDate;
}

export const formatDateToYYYYMMDD = (date) => {
  const _date = new Date(date);
  const year = _date.getFullYear();
  const month = String(_date.getMonth() + 1).padStart(2, '0');
  const day = String(_date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}