import React from "react";
import { Button } from "primereact/button";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import supabase from '../../config/supabase.js';

export default function ProfileSettings() {
  const nav = useNavigate();
  useEffect(() => {
    localStorage.setItem("activeTab", 5);
  }, []);



  const logout = async () => {
    try {
      const { error: e } = await supabase.auth.signOut();
      if (e) console.log(e);
      localStorage.clear();
      window.location.href = "/";
    } catch (error) {
      if (error) console.log("Error logging out:", error.message);
    }
  };

  return (
    <div className='box-flex-wrap'>
      <Button
        className='prime'
        label='Privacy Policy'
        onClick={() => nav("/privacy-policy")}
      ></Button>
      <Button
        className='prime'
        label='Terms of Service'
        onClick={() => nav("/terms-of-service")}
      ></Button>
      <Button
        className='prime'
        label='Reset password'
        onClick={() => nav("/password-reset")}
      ></Button>
      <Button
        className='btn-delete'
        label='Logout'
        onClick={async () => await logout()}
      ></Button>
    </div>
  );
}
