import supabase from "../config/supabase.js";
import {
  getCurrentUserFromSupabase,
  getIdsFromCurrentUserByParentId,
} from "./currentUser.js";
const paidForUserId = "paid_for_user_id";


export const getAllPayments = async () => {
  const { data, error } = await supabase.from("payments").select(
    `
    id,
    created_at,
    amount,
    is_anonymous,
    type,
    payment_method,
    pricing (name, price),
    paidBy:users!paid_by_user_id (first_name, last_name),  paidFor:users!paid_for_user_id (first_name, last_name)`
  );
  if (error) {
    console.log("An error occured: getAllPayments");
    console.log(error);
    return;
  }
  return data;
};

export const checkIfPaymentIdExists = async (paymentId) => {
  const { data, error } = await supabase
    .from("payments")
    .select("id")
    .eq("id", paymentId)
    .limit(1)
    .maybeSingle();
  if (error) {
    //PGRST116 is the code if more than 1 or no items where returned when requesting a single item
    if (error.code === "PGRST116") return false;
    console.log("An error occured in checkIfPaymentIdExists: ");
    console.log(error);
    return false;
  }
  return data === null ? false : true;
};

export const getTotalAmountPayedFromUser = async (uuid) => {
  const { data, error } = await supabase
    .from("payments")
    .select("amount")
    .eq(paidForUserId, uuid);
  if (error) {
    console.log("An error occured: getTotalAmountPayedFromUser");
    console.log(error);
    return;
  }

  const totalAmount = data.reduce((acc, curr) => {
    return acc + curr.amount;
  });
  return totalAmount.amount;
};

export const getRemainingAmountToPayFromUser = async (price, uuid) => {
  const { data, error } = await supabase
    .from("payments")
    .select("amount")
    .eq("paid_for_user_id", uuid); // Ensure the column name is a string and correct

  if (data.length === 0) return price; // If the user has not paid anything, return the full price
  if (error) {
    console.log("An error occurred: ", error.message); // Log the error message for debugging
    return null; // Return null or an appropriate value indicating an error
  }

  let amount = 0;
  data.forEach((item) => {
    amount += item.amount;
  });

  return price - amount;
};

export const getPaymentTypesFromUser = async (uuid) => {
  if (uuid === undefined || uuid === null) {
    console.log("uuid is undefined or null in getPaymentTypesFromUser");
    return;
  }
  const { data, error } = await supabase
    .from("payments")
    .select("type")
    .eq(paidForUserId, uuid);
  if (error) {
    console.log("An error occured in getPaymentTypesFromUser: ");
    console.log(error);
    return;
  }

  const paymentTypeArray = [];
  data.map((p) => {
    paymentTypeArray.push(p.type);
    return p.payment_type;
  });

  return paymentTypeArray;
};

export const getPaymentsForUser = async () => {
  const user = await getCurrentUserFromSupabase();
  const { data, error } = await supabase
    .from("payments")
    .select("*")
    .eq(paidForUserId, user.id);
  if (error) {
    console.log("An error occured: getPaymentsForUser");
    console.log(error);
    return;
  }
  return data;
};

//https://stackoverflow.com/questions/72816894/supabase-how-to-query-the-same-table-twice
//<CustomeObjectName>:<TableName>!<ForeignKeyName>
export const getPaymentsByUser = async () => {
  const user = await getCurrentUserFromSupabase();
  const { data, error } = await supabase
    .from("payments")
    .select(
      `
    id,
    created_at,
    amount,
    is_anonymous,
    type,
    payment_method,
    pricing (name, price),
    paidBy:users!paid_by_user_id (first_name, last_name),  paidFor:users!paid_for_user_id (first_name, last_name)
  `
    )
    .or(`paid_by_user_id.eq.${user.id},and(paid_for_user_id.eq.${user.id})`);
  if (error) {
    console.log("An error occurred: getPaymentsByUser");
    console.log(error);
    return;
  }
  return data;
};

export const getPaymentsFromUserByFromParentId = async () => {
  const arrayId = await getIdsFromCurrentUserByParentId();

  if (!arrayId.length || arrayId.length === 0) {
    return null;
  }

  const { data, error } = await supabase
    .from("payments")
    .select(
      `    id,
    created_at,
    amount,
    is_anonymous,
    type,
    payment_method,
    pricing (name, price),
    paidBy:users!paid_by_user_id (first_name, last_name),  paidFor:users!paid_for_user_id (id,first_name, last_name)`
    )
    .in("paid_for_user_id", arrayId);
  if (error) {
    console.log("An error occured: getPaymentsFromUserByFromParentId");
    console.log(error);
    return;
  }

  return data;
};

export const getPaymentFromUserForProgress = async () => {
  const arrayId = await getIdsFromCurrentUserByParentId();
  if (arrayId.length || arrayId.length === 0) {
    return null;
  }
  const { data, error } = await supabase
    .from("payments")
    .select("amount, paid_for_user_id")
    .in("paid_for_user_id", arrayId);
  if (error) {
    console.log("An error occured: getPaymentFromUserForProgress");
    console.log(error);
    return;
  }

  const hashmap = new Map();

  data.forEach((item) => {
    const userId = item.paid_for_user_id;
    const amount = item.amount;

    if (hashmap.has(userId)) {
      // If the user id already exists in the hashmap, add the amount to the existing total
      hashmap.set(userId, hashmap.get(userId) + amount);
    } else {
      // If the user id doesn't exist, initialize it with the amount
      hashmap.set(userId, amount);
    }
  });
  return hashmap;
};

export const deleteAllPayments = async () => {
  const { data, error } = await supabase
    .from("payments")
    .delete()
    .neq("id", "caada6e2-6f65-4a53-8dc5-bd5716b41722");
  if (error) {
    console.log("An error occured: deleteAllPayments");
    console.log(error);
    return;
  }

  console.log("Deleted all payments");
  return data;
};

export const getTotalPayments = async () => {
  const { data, error } = await supabase.from("payments").select("amount");
  if (error) {
    console.error("An error occurred: getTotalPayments", error);
    throw error; // or return a specific error value, depending on your error handling strategy
  }

  const totalAmount = data.reduce((acc, curr) => acc + curr.amount, 0);
  return totalAmount; // totalAmount is a number, so just return it directly
};
