import React, { useEffect, useState } from "react";
import { getAllSizesData } from "@db/sizes.js";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Dialog } from "primereact/dialog";
import ColorSizes from "./sizes/ColorSizes";
import ExportExcelButton from "../buttons/ExportExcelButton.js";
import { getAccessLevelOfCurrentUser } from "@db/admin.js";


export default function SizesAdmin() {
  const [sizesObj, setSizesObj] = useState({});
  const [sizesList, setSizesList] = useState([]);
  const [selectRow, setSelectRow] = useState(null);
  const [visible, setVisible] = useState(false);
  const [arrayNames, setArrayNames] = useState([]);
  const [headerTitle, setHeaderTitle] = useState("General sizes");
  const [accessLevel, setAccessLevel] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getAllSizesData();
      setAccessLevel(await getAccessLevelOfCurrentUser());
      setSizesObj(data);
      setSizesList(
        data.sizes.map((s) => ({
          label: s.label,
          total: s.total,
          names: s.names, // Store the array of names directly
        }))
      );
    };
    fetchData();
  }, []);

  const handleRowSelection = (e) => {
    setSelectRow(e.value); // Update the selected row state
    const selectedSize = sizesList.find((size) => size.label === e.value.label);
    if (selectedSize) {
      setArrayNames(selectedSize.names.sort()); // Update the arrayNames state with the names from the selected row
      setVisible(true); // Show the dialog with names
      setHeaderTitle(`${selectedSize.label} list`); // Update the header title
    }
  };

  return (
    <>
      <Accordion activeIndex={0}>
        <AccordionTab header='General sizes'>
          <div className='box-column'>
            <h3 className='box-center-grid'>Showing sizes for all users</h3>
            <ExportExcelButton value={sizesList} fileName='General_sizes' />
          </div>
          <DataTable
            showGridlines
            stripedRows
            value={sizesList}
            selectionMode='single'
            selection={selectRow}
            onSelectionChange={handleRowSelection}
          >
            <Column field='label' header='Name' />
            <Column field='total' header='Total' />
          </DataTable>
        </AccordionTab>
        {accessLevel >= 3 && (
          <AccordionTab header='Color sizes'>
            <ColorSizes />
          </AccordionTab>
        )}
      </Accordion>
      <Dialog
        header={headerTitle}
        visible={visible}
        style={{ maxWidth: "800px" }}
        onHide={() => setVisible(false)}
      >
        {arrayNames.map((name, index) => (
          <p key={index}>{name}</p> // Use index as key for list items
        ))}
      </Dialog>
    </>
  );
}
