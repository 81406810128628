import supabase from "../config/supabase.js";

export const getCampInfo = async () => {
  const { data, error } = await supabase
    .from("camp_info")
    .select("*")
    .limit(1)
    .single();
  if (error) {
    console.log("An error occured in getCampInfo:");
    console.log(error);
    return;
  }
  return data;
};

export const getCampInfoParams = async (params) => {
  const { data, error } = await supabase
    .from("camp_info")
    .select(params)
    .limit(1)
    .single();
  if (error) {
    console.log("An error occured in getCampInfoParams:");
    console.log(error);
    return;
  }
  return data;
};

export const getCampStartDateFormat = async () => {
  const { data, error } = await supabase
    .from("camp_info")
    .select("start_date")
    .limit(1)
    .single();
  if (error) {
    console.log("An error occured in getCampStartDate:");
    console.log(error);
    return;
  }
  return new Date(data.start_date);
};

export const getCampEndDateFormat = async () => {
  const { data, error } = await supabase
    .from("camp_info")
    .select("end_date")
    .limit(1)
    .single();
  if (error) {
    console.log("An error occured in getCampEndDate:");
    console.log(error);
    return;
  }

  return new Date(data.end_date);
};

export const getCampInfoTotalBeds = async () => {
  const { data, error } = await supabase
    .from("camp_info")
    .select("total_beds")
    .limit(1)
    .single();
  if (error) {
    console.log("An error occured in getCampInfoTotalBeds:");
    console.log(error);
    return;
  }
  return data.total_beds;
};

export const getIsDeadlinePassed = async () => {
  const { data, error } = await supabase
    .from("camp_info")
    .select("registration_deadline")
    .limit(1)
    .single();
  if (error) {
    console.log("An error occured in getIsDeadlinePassed:");
    console.log(error);
    return;
  }
  const deadline = new Date(data.registration_deadline).getTime();
  const now = Date.now();
  return deadline < now;
};
