import React from "react";
import "../../styles/googleForm.css";

export default function GoogleFormRadioInput({
  handleChange,
  question,
  entryId,
  removeMaybe,
  labels = ["Yes", "No", "Maybe"], // Default labels fallback
}) {
  return (
    <section className="google-form-radio">
      <p>{question}</p>
      <div>
        <input
          type="radio"
          name={entryId}
          value={labels[0] || "Yes"}
          id="yes"
          onChange={handleChange}
          required
        />
        <label htmlFor="yes">{labels[0] || "Yes"}</label>
      </div>
      <div>
        <input
          type="radio"
          name={entryId}
          value={labels[1] || "No"}
          id="no"
          onChange={handleChange}
          required
        />
        <label htmlFor="no">{labels[1] || "No"}</label>
      </div>
      {!removeMaybe && (
        <div>
          <input
            type="radio"
            name={entryId}
            value={labels[2] || "Maybe"}
            id="maybe"
            onChange={handleChange}
            required
          />
          <label htmlFor="maybe">{labels[2] || "Maybe"}</label>
        </div>
      )}
    </section>
  );
}