import axios from "axios";
import { useNavigate } from "react-router-dom";
import supabase from "../config/supabase.js";
import { getPricingIdByName } from "@/db/pricing.js";
import {
  setUsersNotRegisteredRoomsToStayIn,
} from "@/db/users.js";
import {resetAllUsersRegistrationAndPayments} from "@/db/testing.js";
import { Button } from "primereact/button";
import { getUrlLocationOrigin } from "../utils/locationUrl.js";
import { getUserInfoForEmail } from "@/db/users.js";
import { getCurrentUserFromSupabase } from "@db/currentUser.js";

export default function Test() {
  const nav = useNavigate();

  const handlegetPricingIdByName = async () => {
    const data = await getPricingIdByName("Senior");
    console.log(data);
  };

  const handleUrl = () => {
    const url = getUrlLocationOrigin();
    console.log(url);
  };

  const handleUpdate = async () => {
    await setUsersNotRegisteredRoomsToStayIn();
  };

  const resetRegistration = async () => {
    await resetAllUsersRegistrationAndPayments();
    alert("Reset Complete");
  };

  const sendEmail = async () => {
    const user = await getCurrentUserFromSupabase();
    const userdataForEmail = await getUserInfoForEmail(user.id);

    const { data, error } = await supabase.functions.invoke("send-receipt", {
      body: {
        userdataForEmail,
      },
    });
  };

  const beAdmin = async () => {
    const user = await getCurrentUserFromSupabase();
    const { data, error } = await supabase.from("admin").insert({
      id: user.id,
      access_level: 5,
    });

    if (error) {
      console.log(error);
    } else {
      console.log(data);
      window.location.reload();
      alert("You are now admin");
    }
  };

  const beAUser = async () => {
    const user = await getCurrentUserFromSupabase();
    const { data, error } = await supabase
      .from("users")
      .update({
        id: user.id,
      })
      .eq("id", "23b0fe8c-81b0-49fe-a1b6-3f921cc0112d");
    if (error) {
      alert(error);
      console.log(error);
    } else {
      console.log(data);
      window.location.reload();
      alert("You are now Michtam");
    }
  };

  return (
    <div className='center'>
      <div className='buttons-column-center '>
        <h1 style={{ color: "white" }}>Test site</h1>
        {/*
        <Button label='Get Pricing' onClick={handlegetPricingIdByName} />
        <Button label='Get Url' onClick={handleUrl} />
        <Button label='Update name' onClick={handleUpdate} />
        <Button label='Send email' onClick={sendEmail} />
        */}
        <Button
          label='Reset Registration and payment'
          onClick={resetRegistration}
        />
        <Button label='Be Admin ' onClick={beAdmin} />

        <Button label='Be a User' onClick={beAUser} />
      </div>
    </div>
  );
}
