import supabase from "@config/supabase.js";
import { calculateAgeCategory } from "@utils/getAge.js";
import { ageCategory } from "../utils/orderLists.js";

export const getTeamsList = async () => {
  const { data, error } = await supabase
    .from("teams")
    .select("id,name,color_code,leader_id (id, first_name, last_name)")
    .order("id", { ascending: true })
  if (error) {
    console.log("An error occured: ");
    console.log(error);
    return;
  }
  return data;
};

export const getUsersTeams = async () => {
  const { data, error } = await supabase
    .from("users")
    .select(
      "id,first_name,last_name, birthday, gender, is_inactive, team_id (id,name,color_code,leader_id(id, first_name,last_name))"
    )
    .eq("is_registered", true)
    .eq("is_attending", true);

  if (error) {
    console.log("An error occured: ");
    console.log(error);
    return;
  }
  return data;
};

export const getTeamsAndPeople = async (people) => {
  const { data, error } = await supabase
    .from("teams")
    .select("id, name, color_code, leader_id (id, first_name, last_name)")
    .order("id", { ascending: true })

  if (error) {
    console.log("An error occurred: ");
    console.log(error);
    return;
  }

  const teamsFormatData = data.map((team) => {
    let leaderName = null;

    if (team.leader_id) {
      leaderName = team.leader_id.first_name + " " + team.leader_id.last_name;
    }

    return {
      id: team.id,
      title: team.name,
      subTitle: team.leader_id ? "Leader: " + leaderName : null,
      backgroundColor: team.color_code,
      leader: {
        id: team.leader_id ? team.leader_id.id : null,
        name: leaderName,
      },
      list: [],
    };
  });

  // Add an "unassigned" object at the beginning of the array for unassigned people
  teamsFormatData.unshift({
    id: 0,
    title: "Unassigned",
    subTitle: null,
    leader: {
      id: null,
      name: null,
    },
    list: [],
  });

  // Assign people to teams and categorize by age
  for (const person of people) {
    const teamId = person.team_id ? person.team_id.id : 0;
    const teamIndex = teamsFormatData.findIndex((team) => team.id === teamId);

    // Wait for the category calculation
    const category = await calculateAgeCategory(
      person.birthday,
      person.gender,
      person.is_inactive
    );

    if (teamIndex !== -1) {
      teamsFormatData[teamIndex].list.push({
        id: person.id,
        name: `${person.first_name} ${person.last_name}`,
        category: category,
      });
    }
  }

  // Sort each team's list by age category
  teamsFormatData.forEach((team) => {
    team.list.sort((a, b) => {
      return (
        ageCategory.indexOf(a.category) - ageCategory.indexOf(b.ageCategory)
      );
    });
  });

  return teamsFormatData;
};

export const setTeamsForUser = async (userId, teamId) => {
  const { data, error } = await supabase
    .from("users")
    .update({ team_id: teamId })
    .eq("id", userId);
  if (error) {
    console.log("An error occured: ");
    console.log(error);
    return;
  }
  return data;
};

export const getTeamNamebyId = async (id) => {
  const { data, error } = await supabase
    .from("teams")
    .select("name")
    .eq("id", id)
    .limit(1)
    .single();

  if (error) {
    console.log("An error occured: ");
    console.log(error);
    return;
  }
  return data;
};

export const setTeamName = async (teamId, name) => {
  const { data, error } = await supabase
    .from("teams")
    .update({ name: name })
    .eq("id", teamId);
  if (error) {
    console.log("An error occured: ");
    console.log(error);
    return;
  }
  return data;
};

export const getTeamMembers = async (teamId) => {
  const { data, error } = await supabase
    .from("users")
    .select("id,first_name,last_name")
    .eq("team_id", teamId)
    .eq("is_attending", true);
  if (error) {
    console.log("An error occured: getTeamMembers");
    console.log(error);
    return;
  }
  return data;
};

export const setTeamLeader = async (teamId, leaderId) => {
  const { data, error } = await supabase
    .from("teams")
    .update({ leader_id: leaderId })
    .eq("id", teamId);
  if (error) {
    console.log("An error occured: ");
    console.log(error);
    return;
  }
  return data;
};

export const setTeamColor = async (teamId, color) => {
  console.log(teamId, color);
  const { data, error } = await supabase
    .from("teams")
    .update({ color_code: color })
    .eq("id", teamId);
  if (error) {
    console.log("An error occured: ");
    console.log(error);
    return;
  }
  return data;
};
