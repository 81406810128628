import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button } from "primereact/button";
import supabase from "../config/supabase";
import { Image } from "primereact/image";
import checkmark from "../images/icons/check.png";
import { formatLocalDateTime } from "@utils/dateTime.js";
import "../styles/checkout.css";
import { LoadingData } from "@components/status/Loading.js";
import { PageSlideUp } from "@animations/PageSlide.js";

export default function Checkout() {
  //http://localhost:3000/checkout?paymentid={id}
  const nav = useNavigate();
  const location = useLocation();
  const regex = /\?paymentid=(.*)/;

  const [isValidPaymentId, setIsValidPaymentId] = useState(null);
  const [isPaymentFull, setIsPaymentFull] = useState(false);

  //UseState
  const [paymentInfo, setPaymentInfo] = useState({});

  function isEmpty(value) {
    return (
      value == null || (typeof value === "string" && value.trim().length === 0)
    );
  }

  useEffect(() => {
    const starting = async () => {
      const paymentId = location.search;
      if (isEmpty(paymentId)) {
        setIsValidPaymentId(false);
      } else {
        const match = regex.exec(paymentId);
        // console.log("first match", match[1]);
        handlePaymentId(match[1]);
      }
    };
    starting();
  }, []); // Empty dependency array ensures useEffect runs once on mount

  const handlePaymentId = async (paymentId) => {
    if (isEmpty(paymentId)) return;

    const { data: _resp, error: error } = await supabase.functions.invoke(
      "payment-id",
      {
        body: {
          paymentId: paymentId,
        },
      }
    );

    if (error) {
      console.error("Error: ", error);
      setIsValidPaymentId(false);
      return;
    }

    const resp = JSON.parse(_resp);

    if (resp.status === 200) {
      setIsValidPaymentId(true);

      const dataObject = {
        id: resp.paymentId,
        created_at: resp.createAt,
        payment_type: resp.paymentType,
        amount: resp.amount / 100,
        paid_for_user_id: resp.payeeId,
        paid_by_user_id: resp.payerId,
        pricing_id: resp.pricingName,
        is_anonymous: resp.isAnonymous,
        type: resp.paymentType,
        name: resp.payeeName,
        payment_method: resp.paymentMethod,
        is_registered: resp.isRegistered,
      };

      setPaymentInfo(dataObject);

      if (dataObject.is_registered) setIsPaymentFull(true);
    } else {
      console.log("There was an error: ");
    }
  };

  const navigateToPage = (page) => {
    if (page === "/profile") {
      localStorage.setItem("activeTab", 1);
      nav(page);
    } else if (page === "/payment") {
      nav(page);
    }
  };

  return (
    <>
      <PageSlideUp style={{ overflow: "hidden" }}>
        <LoadingData
          duration={8000}
          textArray={["Please do not leave this page", "Processing payment"]}
        />
      </PageSlideUp>
      <div
        className='bg-green-blue-gradient center'
        style={{ padding: "2rem", margin: "0" }}
      >
        {isValidPaymentId === null ? (
          <div className='bg-white p-8 rounded-lg text-center h-full my-16 checkout-payment-box center'>
            <h2 className='text-2xl font-semibold text-gray-800 mb-4'></h2>
            <p className='text-gray-700'>
              Please wait while we process your payment
            </p>
            <p className='text-gray-700'>This may take a few seconds</p>
          </div>
        ) : isValidPaymentId ? (
          <div className='bg-white p-8 rounded-lg text-center h-full my-16 checkout-payment-box center'>
            {/* Replace 'checkmark' with your image path */}
            <div className='my-16 bg-blue'>
              <h2 className='text-2xl font-semibold text-gray-800 mb-4'>
                Successfully Paid
              </h2>
              <Image src={checkmark} alt='Checkmark' width='100' />{" "}
              <div className='border-t border-gray-200 mt-4 pt-4'>
                {/* Placeholder for payment information */}

                {isPaymentFull ? (
                  <p>
                    Congratulation the person is now registered for our Church
                    Camp 2025, a receipt has been sent to the recipients email.
                  </p>
                ) : (
                  <p>
                    Note: They are still not registered since the person is not
                    paid full yet
                  </p>
                )}
                <p>Payment for: {paymentInfo.name}</p>
                <p>Payment Amount: {paymentInfo.amount} DKK</p>
                <p>
                  Payment Date: {formatLocalDateTime(paymentInfo.created_at)}
                </p>
                <p>Payment Type: {paymentInfo.type}</p>
                <p className='text-word-wrap'>Payment ID: {paymentInfo.id}</p>
              </div>
              <Button
                label='Profile page'
                onClick={() => navigateToPage("/profile")}
              />
              <Button
                label='Payment page'
                onClick={() => navigateToPage("/payment")}
              />
            </div>
          </div>
        ) : (
          <div className='bg-white p-8 rounded-lg text-center h-full checkout-payment-box center'>
            <h2 className='text-2xl font-semibold text-gray-800 mb-4'>
              Payment not found :(
            </h2>
            <p className='text-gray-700'>
              There was an error retriving your payment. Please try again.
            </p>
            <p>If you believe this is a mistake, please contact us</p>
            <Button
              label='Profile page'
              onClick={() => navigateToPage("/profile")}
            />
            <Button
              label='Payment page'
              onClick={() => navigateToPage("/payment")}
            />
          </div>
        )}
      </div>
    </>
  );
}
