import React from "react";
import "../styles/screen.css";

export default function Contact() {
  return (
    <section className='page-text-white bg-black-gradient-grid-2 page-container screen-50-height'>
      <div className='text-left box-width page-box'>
        <h1 className='text-h1'>Contact info</h1>
        <p>Name: Copenhagen Bethel Missionary Baptist Church</p>
        <p>Email: cbmbchurch@gmail.com</p>
        <p>Camp email: camp.cbmbc@gmail.com</p>
        <p>Phone: +45 31238926</p>
        <p>Address: Lærdalsgade 7, 1. sal th, Copenhagen S Denmark</p>
        <p>
          Website:{" "}
          <a href='https://cbmbc.org' target='_blank'>
            cbmbc.org
          </a>
        </p>
        <p>
          CVR:{" "}
          <a
            target='_blank'
            href='https://datacvr.virk.dk/enhed/virksomhed/29998248?fritekst=29998248&sideIndex=0&size=10'
          >
            29998248{" "}
          </a>{" "}
        </p>
      </div>
    </section>
  );
}
