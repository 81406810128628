import React from "react";
import { Card } from "primereact/card";
import { getUrlLocationOrigin } from "@utils/locationUrl.js";

export default function CheckoutBox({
  footer,
  pBlack,
  choice,
  camperChoice,
  priceName,
  paymentTypeChoice,
  currentUserEmail,
  stayAnoymous,
  priceAmount,
  pResult,
  donationAmount,
  accommodation,
  isDeadlinePassed
}) {
  return (
    <div className='checkout-box'>
      <Card title='Checkout' footer={footer}>
        {choice === "Pay for myself" || choice === "Pay for someone else" ? (
          <>
            <p className={pBlack}>Choice: {choice}</p>
            <p className={pBlack}>Camper: {camperChoice}</p>
            <p className={pBlack}>Price type: {priceName}</p>
            <p className={pBlack}>Payment Type: {paymentTypeChoice}</p>
            {choice === "Pay for someone else" ? (
              <>
                <p className={pBlack}>
                  {stayAnoymous === true
                    ? "Stay anonymous: Yes"
                    : "Stay anonymous: No"}
                </p>
              </>
            ) : (
              <p className={pBlack}>Email: {currentUserEmail}</p>
            )}
            {isDeadlinePassed === true && (
              <p className='text-warning-container'>
                The deadline for the payment has passed, and your price has been increased back to 5%.
                </p>
            )}
            {accommodation === "Stay in" && (
              <p className='text-warning-container'>
                {choice === "Pay for myself"  ? "Your" : "Their"} accommodation has been changed to stay in, due to the rooms
                being fully booked. {choice === "Pay for myself"  ? "You" : "They"}  need to bring {choice === "Pay for myself"  ? "your" : "their"}  own sleeping bag, bedding or tent.{" "}
                <a href={getUrlLocationOrigin() + "/pricing"} target='_blank'>
                  Click here to learn more
                </a>
              </p>
            )}
            {priceAmount === null || priceAmount <= 0 ? (
              <p className={pResult}>
                Total Price:
                <span className='text-xl'> </span>
              </p>
            ) : (
              <p className={pResult}>
                Total Price:
                <span className='text-xl'> {priceAmount} </span>
                DKK
              </p>
            )}
          </>
        ) : choice === "Donation" ? (
          <>
            <p className={pBlack}>Choice: {choice}</p>
            <p className={pResult}>
              Amount:
              {donationAmount === null || donationAmount <= 0 ? (
                <span className={pBlack}> 0</span>
              ) : (
                <span className={pBlack}> {donationAmount}</span>
              )}
            </p>
          </>
        ) : (
          <p className={pBlack}>Something went wrong</p>
        )}
      </Card>
    </div>
  );
}
