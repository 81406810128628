import React from "react";

const GoogleFormInputText = ({ handleChange, question, entryId, value, invisible, required }) => {
    const isHidden = invisible ? { display: "none" } : {};

    return (
      <section className="google-form-text" style={isHidden }>
      <label htmlFor={entryId} dangerouslySetInnerHTML={{ __html: question }} />
      <textarea
          name={entryId}
          type="text"
          id={entryId}
          defaultValue={value}
          required={required}
          onChange={handleChange}
        />
      </section>
    );
  };

export default GoogleFormInputText;