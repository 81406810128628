export const getTotalAddCamperAllowed = () => {
  return 7;
};

export const getIsDeadlinePassed = () => {
  const today = new Date();
  const options = { timeZone: "Europe/Copenhagen" };
  const denmarkDate = today.toLocaleString("en-US", options);
  const denmarkDateTime = new Date(denmarkDate);

  // Set deadline to the start of April 22, 2025 (midnight at the beginning of the day)
  const deadline = new Date("2025-03-3");

  // Adjust the deadline to consider the timezone
  deadline.setHours(0, 0, 0, 0);

  return denmarkDateTime >= deadline; // True if it's April 2, 2025, or later in Denmark
};
