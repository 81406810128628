import React, { useState, useEffect } from "react";
import Display from "@components/display/Display.js";
import {
  getTeamsList,
  getUsersTeams,
  getTeamNamebyId,
  getTeamsAndPeople,
} from "@db/teams.js";
import { calculateAgeCategory } from "@utils/getAge.js";
import {ageCategory} from "@utils/orderLists.js";

export default function TeamsDisplay({ displayData, updateData }) {
  const [groupedData, setGroupedData] = useState([]);

  useEffect(() => {
    const fetchTeamData = async () => {
      const users = await getUsersTeams();
      const teamGroup = await getTeamsAndPeople(users);
      setGroupedData(teamGroup);
    };

    // Set grouped data to state
    if (displayData) {
      // Ensure displayData is truthy before setting gr poupedData
      setGroupedData(displayData);
    } else fetchTeamData();
  }, []); // Trigger effect when displayData changes

  useEffect(() => {
    if (updateData) {
      const updateUserCategory = async () => {
        try {
          // Determine the category for updateData
          const category = await calculateAgeCategory(
            updateData.birthday,
            updateData.gender,
            updateData.is_inactive
          );

          setGroupedData((prevData) => {
            return prevData.map((team) => {
              // Remove user from any team they were previously in
              const updatedList = team.list.filter(
                (user) => user.id !== updateData.id
              );

              // If this is the updated user's new team, add them to the list with the category
              if (team.id === updateData.team_id) {
                updatedList.push({ ...updateData, category });

                // Sort the updated list by category order
                updatedList.sort(
                  (a, b) =>
                    ageCategory.indexOf(a.category) -
                    ageCategory.indexOf(b.category)
                );
              }

              return { ...team, list: updatedList };
            });
          });
        } catch (error) {
          console.error("Error updating category:", error);
        }
      };

      updateUserCategory(); // Call the async function
    }
  }, [updateData]); // Runs when updateData changes

  return <Display data={groupedData} categoryOrder={ageCategory} />;
}
