import { useState, useEffect, useRef } from "react";
import {
  setAdminSettingsBool,
  getAllAdminSettings,
} from "@db/admin.js";
import SettingSwitch from "@components/settings/SettingSwitch.js";
import SettingInputNumber from "@components/settings/SettingInputNumber.js";
import { Toast } from "primereact/toast";
export default function SettingsAdmin() {
  const [settings, setSettings] = useState([]); // [name, bool, description, data_type, number_value, string_value
  const toast = useRef(null);

  useEffect(() => {
    // Get settings from database
    const getSettings = async () => {
      // const rooms = await getAdminSettingsBool("rooms");
      // const teams = await getAdminSettingsBool("teams");
      const settings = await getAllAdminSettings();
      setSettings(settings);
      // setCheckedRooms(rooms);
      // setCheckedTeams(teams);
    };
    getSettings();
  }, []);

  const onSettingsOnChange = async (onSelectChange, settingName, value) => {
    onSelectChange(value);
    const status = await setAdminSettingsBool(settingName, value);
    if (!status) {
      onSelectChange(!value);
      showError(settingName, value);
    } else {
      showSuccess(settingName, value);
    }
  };

  const showSuccess = (settingName, value) => {
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "Setting " + settingName + " changed to " + value,
      life: 3000,
    });
  };
  const showError = (settingName, value) => {
    toast.current.show({
      severity: "error",
      summary: "Error",
      detail: "Failed to change setting " + settingName + " to " + value,
      life: 3000,
    });
  };

  return (
    <>
      <Toast ref={toast} />
      <div className='box-center-grid'>
        <div className='box-columns'>
          <h1 className='text-center'>Settings</h1>
          {settings.map((setting, index) => {
            switch (setting.data_type) {
              case "INT": {
                return <SettingInputNumber 
                key={index}
                setting={setting} />;
              }
              case "BOOL": {
                return (
                  <SettingSwitch
                  key={index}
                    setting={setting}
                    onUpdate={setAdminSettingsBool}
                  />
                );
              }
            }
          })}
        </div>
      </div>
    </>
  );
}
