import { useState, useEffect } from "react";
import { Menubar } from "primereact/menubar";
import campLogo from "../images/logos/camp_logo_2025_black.png";
import "@styles/menuBar.css";
import { useNavigate } from "react-router-dom";
import supabase from "../config/supabase.js";
import { SplitButton } from "primereact/splitbutton";
import { DeveloperMode } from '../config/DeveloperMode.js';

export default function MenuBar() {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(null);
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const [isDeveloperMode, setIsdeveloperMode] = useState(false);

  const checkIfUserLoggedIn = async () => {
    const { data } = await supabase.auth.getSession();
    if (data.session === null) {
      setIsLoggedIn(false);
    } else {
      setIsLoggedIn(true);
    }
    if (data.session !== null) {
      await checkIfUserIsAdmin(data.session.user.id);
    }
  };
  const checkIfUserIsAdmin = async (uuid) => {
    const { data } = await supabase.from("admin").select("id").eq("id", uuid);

    if (data && data.length > 0) {
      setIsUserAdmin(true);
    } else {
      setIsUserAdmin(false);
    }

    const isDeveloper = DeveloperMode();
    setIsdeveloperMode(isDeveloper);
  };

  useEffect(() => {
    checkIfUserLoggedIn();
  }, []);

  const items = [
    {
      label: "News",
      items: [
        {
          label: "About",
          icon: "pi pi-book",
          command: () => {
            navigate("/about");
          },
        },
        {
          label: "Group Formation Algorithm",
          icon: "pi pi-code",
          command: () => {
            navigate("/group-formation");
          },
        },
        {
          label: "Old website (2013 - 2022)",
          icon: "pi pi-globe",
          command: () => {
            window.open("https://www.cbmbc.org/church-camp.html", "_blank");
          },

        },
        {
          label: "YouTube playlist",
          icon: "pi pi-youtube",
          items: [
            {
              label: "Church Camp 2024",
              icon: "pi pi-youtube",
              command: () => {
                window.open(
                  "https://youtube.com/playlist?list=PLK1Ko106Om3qbd6-EQBSBpDBIZrgDR1I2&si=p36imi-8Xgz8cxi1",
                  "_blank"
                );
              },
            },
            {
              label: "Church Camp 2023",
              icon: "pi pi-youtube",
              command: () => {
                window.open(
                  "https://youtube.com/playlist?list=PLK1Ko106Om3pM4gVh3WlfFmhA06cZtKmJ&si=I2UoryBic2XhsYba",
                  "_blank"
                );
              },
            },
            {
              label: "Church Camp 2022",
              icon: "pi pi-youtube",
              command: () => {
                window.open(
                  "https://youtube.com/playlist?list=PLK1Ko106Om3pXtfal0eLMJcH0o-ZOnQKE&si=4ehYNFGyw4I1kj3C",
                  "_blank"
                );
              },
            },
            {
              label: "Church Camp 2019",
              icon: "pi pi-youtube",
              command: () => {
                window.open(
                  "https://youtube.com/playlist?list=PLK1Ko106Om3pCqpxBHX7csm_HOGkAf3x9&si=gNU8S33f3YEspaUo",
                  "_blank"
                );
              },
            },
          ],
        },
      ],
    },
    {
      label: "Pricing",
      icon: "pi pi-tags",
      command: () => {
        navigate("/pricing");
      },
    },
    {
      label: "Dashboard",
      icon: "pi pi-chart-bar",
      visible: isUserAdmin,
      command: () => {
        navigate("/admin");
      },
    },
    {
      label: "Campers",
      icon: "pi pi-users",
      badge: 3,
      visible: isLoggedIn,
      command: () => {
        navigate("/campers");
      },
    },
    {
      label: "Payment",
      icon: "pi pi-wallet",
      badge: 3,
      visible: isLoggedIn,
      command: () => {
        navigate("/payment");
      },
    },
    {
      label: "Contact",
      icon: "pi pi-envelope",
      command: () => {
        navigate("/contact");
      },
    },
    {
      label: "Developer mode",
      icon: "pi pi-wrench",
      visible: isDeveloperMode,
      command: () => {
        navigate("/test");
      },
    }
  ];

  const profileItems = [
    {
      label: "Profile",
      icon: "pi pi-user",
      command: () => {
        navigate("/profile");
      },
    },
    {
      label: "Payment",
      icon: "pi pi-wallet",
      badge: 3,
      visible: isLoggedIn,
      command: () => {
        navigate("/payment");
      },
    },
    {
      label: "Add camper",
      icon: "pi pi-user-plus",
      command: () => {
        localStorage.setItem("activetab", 2);
        navigate("/profile");
      },
    },
    {
      label: "Logout",
      icon: "pi pi-sign-out",
      command: () => {
        logout();
      },
    },
  ];

  const loginItems = [
    {
      label: "Login",
      icon: "pi pi-sign-in",
      command: () => {
        navigate("/login");
      },
    },
    {
      label: "Sign Up",
      icon: "pi pi-user-plus",
      command: () => {
        navigate("/signup");
      },
    },
  ];
  const logout = async () => {
    try {
      const { error: e } = await supabase.auth.signOut();
      if (e) console.log(e);
      localStorage.clear();
      window.location.href = "/";
    } catch (error) {
      if (error) console.log("Error logging out:", error.message);
    }
  };

  const start = (
    <img
      alt='logo'
      src={campLogo}
      height='40'
      className='mr-2'
      onClick={() => navigate("/")}
    ></img>
  );
  const end = (
    <div className='flex align-items-center gap-2'>
      {isLoggedIn ? (
        <>
          {/* <Avatar
            image='https://cdn-icons-png.flaticon.com/512/456/456212.png'
            shape='circle'
            onClick={() => navigate("/profile")}
          /> */}
          {/* <button className='menu-bar-btn-black' onClick={logout}>
            Logout
          </button> */}
          <SplitButton
            icon='pi pi-user'
            model={profileItems}
            
            onClick={() => {
              localStorage.setItem("activetab", 0);
              navigate("/profile");
            }}
          />
        </>
      ) : (
        <SplitButton
          label='Sign up'
          model={loginItems}
          
          onClick={() => navigate("/signup")}
        />
      )}
    </div>
  );

  return (
    <>
      {/* <div className='menu-bar-container'> */}
      {/* <Menubar model={items} start={start} end={end} /> */}
      {/* </div> */}
      <div className='menubar'>
        <Menubar model={items} start={start} end={end} />
      </div>
    </>
  );
}
