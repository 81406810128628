import { motion } from "framer-motion";

export const PageSlideUp = ({ children, isVisible }) => {
  return (
    <motion.div
      animate={{ y: isVisible ? 0 : 2500, zIndex: 100 }}
      transition={{ duration: 5, type: "tween" }}
    >
      {children}
    </motion.div>
  );
};
