import { useEffect, useState } from "react";
import { getSizesColor, sizeSorter } from "@db/sizes.js";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputSwitch } from "primereact/inputswitch";
import ExportExcelButton from "@components/buttons/ExportExcelButton.js";

export default function ColorSizes() {
  const [tableDataNames, setTableDataNames] = useState([]);
  const [tableDataWithoutNames, setTableDataWithoutNames] = useState([]);
  const [teamColumns, setTeamColumns] = useState([]);
  const [showNames, setShowNames] = useState(false);

  useEffect(() => {
    const fetchSizesColor = async () => {
      const sizesColor = await getSizesColor();
      // Create a unique list of all sizes including baby sizes
      const allSizes = Object.values(sizesColor)
        .flatMap((team) => Object.keys(team))
        .filter((value, index, self) => self.indexOf(value) === index);

      // Sort sizes according to the custom sorter
      allSizes.sort((a, b) => sizeSorter({ label: a }, { label: b }));

      // Prepare columns for teams
      const teamColumns = Object.keys(sizesColor).sort();

      // Create the table data structure for each size
      const sizeRowsNames = allSizes.map((sizeLabel) => {
        const row = { sizeLabel };
        teamColumns.forEach((team) => {
          const sizeInfo = sizesColor[team][sizeLabel];
          row[team] = sizeInfo
            ? `${sizeInfo.total} (${sizeInfo.people.join(", ")})`
            : "-";
        });
        return row;
      });

      const sizeRowsWithoutNames = allSizes.map((sizeLabel) => {
        const row = { sizeLabel };
        teamColumns.forEach((team) => {
          const sizeInfo = sizesColor[team][sizeLabel];
          row[team] = sizeInfo ? sizeInfo.total : 0;
        });
        return row;
      });

      setTableDataNames(sizeRowsNames);
      setTableDataWithoutNames(sizeRowsWithoutNames);
      setTeamColumns(teamColumns);
    };

    fetchSizesColor();
  }, []);

  // Create dynamic columns for each team
  const dynamicColumns = teamColumns.map((team) => {
    return <Column key={team} field={team} header={team} />;
  });

  // Handler to toggle between showing names or not
  const toggleShowNames = () => {
    setShowNames(!showNames);
  };

  return (
    <>
      <div className='box-column'>
        <h3 className='box-center-grid'>Showing only registered campers</h3>
        <div className='box-row'>
          <InputSwitch checked={showNames} onChange={toggleShowNames} />
          <p className='box-center-grid text-no-space'>Show names</p>
        </div>
      <ExportExcelButton value={showNames ? tableDataNames : tableDataWithoutNames}
      fileName="sizes"
      />
      </div>
      <DataTable
        showGridlines
        stripedRows
        value={showNames ? tableDataNames : tableDataWithoutNames}
        selectionMode='single'
      >
        <Column field='sizeLabel' header='Size' />
        {dynamicColumns}
      </DataTable>
    </>
  );
}
