import { Outlet, Navigate } from "react-router-dom";
import { React, useEffect, useState } from "react";
import supabase from "../config/supabase";
import { DeveloperMode } from "@/config/DeveloperMode";
import Loading from "@components/status/Loading";


// let isTestMode = DeveloperMode();
let isTestMode = false;

const PrivateRoutes = () => {
  const [auth, setAuth] = useState(null); // Set initial state to null
  const [loading, setLoading] = useState(true); // Add loading state

  useEffect(() => {
    setTimeout(() => {
      checkIfUserLoggedIn();
    }, 1000); // 5 seconds delay before checking user authentication
  }, []);

  const checkIfUserLoggedIn = async () => {
    const { data } = await supabase.auth.getSession();
    if (isTestMode) {
      setAuth(true);
    } else if (data.session === null) {
      setAuth(false);
    } else {
      setAuth(true);
    }
    // setTimeout(() => {
      setLoading(false);
    // }, 1000); // 5 seconds delay before updating loading state
  };

  // Show loading spinner while fetching authentication status
  if (loading) {
    return <Loading />;
  }

  return auth ? <Outlet /> : <Navigate to='/login' />;
};

export default PrivateRoutes;
