import { getCampStartDateFormat } from "@db/campInfo.js";

export const calculateAgeByDate = async (birthday) => {
  const birthDate = new Date(birthday);
  const startDate = await getCampStartDateFormat();

  let age = startDate.getFullYear() - birthDate.getFullYear();
  if (
    startDate.getMonth() < birthDate.getMonth() ||
    (startDate.getMonth() === birthDate.getMonth() &&
      startDate.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
};

export const isSharingBed = (birthday) => {
  const age = calculateAgeByDate(birthday);
  return age <= 7;
};

export const calculateAgeCategory = async (birthday, gender, inactive) => {
  if (inactive) return "Inactive";

  const startDate = await getCampStartDateFormat();
  const age = calculateAgeByDate(birthday, startDate);

  if (age >= 25) {
    return gender; // Assuming gender is 'Male' or 'Female'
  } else if (age >= 13) {
    return "Youth";
  } else if (age >= 4) {
    return "Children";
  } else {
    return "Toddler";
  }
};
