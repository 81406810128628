import React, { useRef, useState, useEffect } from "react";
import { Card } from "primereact/card";
import "../styles/pricing.css";
import mobilePay_logo from "../images/payment_method/MobilePay_logo.svg";
import MasterCard_logo from "../images/payment_method/MasterCard_logo.svg";
import Visa_logo from "../images/payment_method/Visa_logo.png";
import Dankort_logo from "../images/payment_method/Dankort_logo.png";
import Paypal_logo from "../images/payment_method/Paypal_logo.png";
import ApplePay_logo from "../images/payment_method/ApplePay_logo.svg";
import Trusty_logo from "../images/payment_method/Trustly_logo.png";
import { getAllPricingInfo } from "@db/pricing.js";
import { Image } from "primereact/image";
import { Accordion, AccordionTab } from "primereact/accordion";
import { SlideUp } from "../animations/Slide.js";
import { setOriginalPrice } from "../utils/pricingCalculate.js";
import { getIsDeadlinePassed } from '../db/campInfo.js';

export default function Pricing() {
  const [priceMap, setPriceMap] = useState(new Map());
  const [isDeadlinePassed, setIsDeadlinePassed] = useState(false);

  useEffect(() => {
    const fetchPricing = async () => {
      const data = await getAllPricingInfo();
      const newPriceMap = new Map();

      data.forEach((c) => {
        newPriceMap.set(c.code, {
          id: c.id,
          price: c.price,
          name: c.name,
          code: c.code,
        });
      });

      setPriceMap(newPriceMap);
      setIsDeadlinePassed(await getIsDeadlinePassed());
    };


    fetchPricing();

  }, []); // Ensure the useEffect hook runs only once on component mount

  const renderCardPricingItems = (key) => {
    switch (key) {
      case "REG":
        return (
          <>
            {/* Regular */}
            <li>Accommodation in a shared room - 400 DKK</li>
            <li>The food & carriage transport 450 DKK</li>
            <li>An available bus seat</li>
            <li>Camp T-shirt - 100 DKK</li>
          </>
        );
      case "SEN":
        return (
          <>
            {/* Senior */}
            <li>
              Everything included in the {priceMap.get("REG")?.name} price
            </li>
            <li>10% discount</li>
          </>
        );
      case "PRT":
        return (
          <>
            {/* Preteen */}
            <li>
              Everything included in the {priceMap.get("REG")?.name} price
            </li>
            <li>Sunday School lessons</li>
            <li>30% discount</li>
          </>
        );
      case "PRS":
        return (
          <>
            {/* Preschool */}
            <li>
              Everything included in the {priceMap.get("PRT")?.name} price
            </li>
            <li>50% discount</li>
            <li>
              They will need to share a bed with a parent/guardian if they
              choose room as accommodation
            </li>
          </>
        );
      case "TOD":
        return (
          <>
            {/* Toddler */}
            <li>
              Everything included in the {priceMap.get("PRT")?.name} price
            </li>
            <li>Everything is free except the T-shirt which cost 100 DKK</li>
            <li>
              They will need to share a bed with a parent/guardian if they
              choose room as accommodation
            </li>
          </>
        );
      case "STI":
        return (
          <>
            {/* Stay in */}
            <li>
              Everything included in the {priceMap.get("REG")?.name} price
              except room
            </li>
            <li>Bring your own tent or a sleeping bag/matress</li>
          </>
        );
      case "STO":
        return (
          <>
            {/* Stay out */}
            <li>
              Everything included in the {priceMap.get("REG")?.name} price
              except a room
            </li>
            <li>This is for the people who will not stay at the camp</li>
          </>
        );
      case "ODS":
        return (
          <>
            {/* Outisde DK and SE */}
            <li>
              Everything included in the {priceMap.get("REG")?.name} price
            </li>
            <li>10% discount</li>
            <li>
              This is for anyone who is travelling from outside Denmark and
              Sweden
            </li>
          </>
        );
      case "SIO":
        return (
          <>
            {/* Stay in overnight */}
            <li>For those who will stay for one night at the camp</li>
            <li>
              <strong>
                NOTE: DO NOT PAY AND REGISTER ON THE WEBSITE. YOU WILL PAY ON
                ARRIVAL AT CAMP
              </strong>
            </li>
          </>
        );
      case "SWI":
        return (
          <>
            {/* Sunday walk in */}
            <li>
              For those who will attend the camp on Sunday and would like to
              have a meal
            </li>
            <li>
              <strong>
                NOTE: DO NOT PAY AND REGISTER ON THE WEBSITE. YOU WILL PAY ON
                ARRIVAL AT CAMP
              </strong>
            </li>
          </>
        );

      case "CMP":
        return (
          <>
            <li>Special prices for specific persons</li>
          </>
        );
      case "KST":
        return (
          <>
            <p>
              For people who will be working in the kitchen during the camp.
              Contact us if you are interested. The price will include bed as
              well.
            </p>
          </>
        );
      default:
        return <li>Special prices for specific persons</li>;
    }
  };

  const renderCard = (key) => {
    const priceInfo = priceMap.get(key);
    const isSpecial = [2, 4].includes(key); // Example condition for special styles or content
    const className = `pricing-card ${isSpecial ? "gradient-yellow" : ""}`;

    const arrayPriceNotIncrease = [8, 9, 10];
    const displayPrice = isDeadlinePassed && !arrayPriceNotIncrease.includes(priceInfo?.id) 
    ? setOriginalPrice(priceInfo?.price)
    : priceInfo?.price;

    return (
      <Card
        title={`${displayPrice !== 0 ? displayPrice + " DKK" : "Free"} ${
          priceInfo?.id === 9 ? "per meal" : ``
        }`}
        subTitle={`${priceInfo?.name} ${
          key === 0 ? "(13 years old and above)" : ""
        }`}
        className={className}
      >
        <ul>{renderCardPricingItems(key)}</ul>
      </Card>
    );
  };

  return (
    <>
      <section className='bg-blue-complex-gradient-1 page-container  screen-50-height'>
        <h1 className={`text-h1`}>Pricing</h1>
        <div className='center price-text-box page-text-white'>
          <p>
            These prices reflect the early bird discount of 5% off. After the
            deadline on April 1, 2025, prices will return to their original
            rates. All listed prices are final and will not be changed. We only
            accept online payments unless otherwise stated. Please note that all
            payments are non-refundable.{" "}
          </p>
        </div>
        <div className={`price-card-flex`}>
          {Array.from(priceMap.keys()).map((key, index) => (
            <SlideUp key={key} delay={index}>
              {renderCard(key)}
            </SlideUp>
          ))}
        </div>
      </section>
      <section className='payment-method-section'>
        <h1 className='text-h1 '> FAQ</h1>
        <div className='faq-div center'>
          <Accordion>
            <AccordionTab header='Booking terms and condition'>
              <p>
                The Church Camp operates on a first-come, first-served basis,
                ensuring that each fully registered participant is provided with
                a room and bed for accommodation. Due to limited space, it is
                crucial to register early to secure a spot. In cases where a
                participant has booked but not paid in full, or if the camp
                reaches full capacity, the booking will be adjusted to an
                alternative accommodation option. This option requires
                participants to bring their own tent or sleeping bag, and the
                fee for this accommodation is slightly lower than that for a
                room. The booking system will automatically apply this change.
              </p>
            </AccordionTab>
            <AccordionTab header='Cancellation rights'>
              <p>
                As the event is organized by a non-profit organization relying
                on volunteers, all funds collected from registrations are
                allocated directly to essential event expenses, such as food and
                transportation. In light of this, the camp has a strict
                no-refund policy for cancellations, ensuring that all resources
                are utilized for the event's necessities and not for individual
                gain. This policy helps manage the camp's limited resources
                effectively and ensures that the event can be enjoyed by all
                participants while supporting the non-profit's objectives.
              </p>
            </AccordionTab>
            <AccordionTab header='Is the registration fee refundable if I am unable to attend the camp?'>
              <p>
                We do not accept any withdrawal or refunds once a purchase has
                been made through our system. This policy ensures clarity and
                consistency in our transactions, allowing us to maintain a
                smooth registration process for everyone.
              </p>
            </AccordionTab>
            <AccordionTab header='Is there a deadline for registration??'>
              <p>
                Yes, you'll need to register before the given deadline. This
                deadline allows us to purchase essentials like food and T-shirts
                in advance and ensures we're well-prepared to kick off the camp
                smoothly. But yes after the deadline, registration is still
                accepted, but please understand that we cannot guarantee your
                T-shirt size. Additionally, participants may need to provide
                their own sleeping arrangements or tents for overnight stays.
              </p>
            </AccordionTab>
            <AccordionTab header='Is there a maximum capacity for the camp?'>
              <p>
                No, there is no maximum capacity for the camp. We are happy to
                welcome as many people as possible to join us for the camp.
                However, we do have a limited number of rooms at the camp. First
                come first serve or else bring your own sleeping arrangements or
                tents for overnight stays.
              </p>
            </AccordionTab>
            <AccordionTab header='Do I receive a receipt or confirmation of my registration payment?'>
              <p>
                Yes when fully paid, the recipient will get an email for
                confirmation of their registration.
              </p>
            </AccordionTab>
            <AccordionTab header='Price inclusivity of VAT'>
              <p>
                Our prices are fixed and do not include VAT. The camp is
                organized by our church and missions and all the people involve
                are volunteers, so therefore, we do not charge VAT.
              </p>
            </AccordionTab>
          </Accordion>
        </div>
      </section>
      <section className='payment-method-section'>
        <h1 className='text-h1 '> Payment Methods</h1>
        <div className='payment-container'>
          <Image src={MasterCard_logo} width='125' />
          <Image src={Visa_logo} width='125' />
          <Image src={mobilePay_logo} width='125' />
          <Image src={Dankort_logo} width='125' />
          <Image src={Paypal_logo} width='125' />
          <Image src={ApplePay_logo} width='125' />
          <Image src={Trusty_logo} width='125' />
        </div>
      </section>
    </>
  );
}
