export const fetchClientInfo = async () => {
    const responseIp = await fetch(
      "https://api.bigdatacloud.net/data/client-info"
    );
    const responseGeocode = await fetch(
      "https://api.bigdatacloud.net/data/reverse-geocode-client"
    );
    const data = await responseIp.json();
    const dataGeocode = await responseGeocode.json();
  
    return { data, dataGeocode };
  };
  