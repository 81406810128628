import supabase from "../config/supabase.js";

let uuid = null;
let email = null;

export const getCurrentUserFromSupabase = async () => {
  const { data, error } = await supabase.auth.getUser();
  if (error) {
    console.log("An error occured while getting user from Supabase:");
    console.log(error);
    return;
  }
  if (data === null) console.log("It is null");
  uuid = data.user.id;
  email = data.user.email;

  const dataReturn = { id: uuid, email: email };
  return dataReturn;
};

export const checkIfCurrentUserExistsInTable = async () => {
  const userData = await getCurrentUserFromSupabase();
  if (userData === null) {
    console.log("There was an error from getting current user from table");
    return null;
  }
  const { data, error } = await supabase
    .from("users")
    .select("*")
    .eq("id", userData.id)
    .limit(1)
    .maybeSingle();
  if (error) {
    console.log("An error occured: ");
    console.log(error);
    return;
  }
  return data == null ? false : true;
};

export const getCurrentUser = async () => {
  const userData = await getCurrentUserFromSupabase();
  if (userData === null) {
    console.log("There was an error from getting current user from table");
    return null;
  }
  const { data, error } = await supabase
    .from("users")
    .select("*")
    .eq("id", uuid)
    .limit(1)
    .single();
  if (error) {
    console.log("An error occured: ");
    console.log(error);
    return;
  }
  return data;
};

export const getCurrentUsersEmail = async () => {
  const userData = await getCurrentUserFromSupabase();
  if (userData === null) {
    console.log("There was an error");
    return null;
  }
  return userData.user.email;
};

export const getCurrentUserIdNameEmail = async () => {
  const userData = await getCurrentUserFromSupabase();
  if (userData === null) {
    console.log("There was an error");
    return null;
  }
  const { data, error } = await supabase
    .from("users")
    .select("first_name, last_name, id, email")
    .eq("id", uuid)
    .limit(1)
    .single();
  if (error) {
    console.log("An error occured: ");
    console.log(error);
    return;
  }
  return data;
};

export const getCurrentUserName = async () => {
  const userData = await getCurrentUserFromSupabase();
  if (userData === null) {
    console.log("There was an error");
    return null;
  }
  const { data, error } = await supabase
    .from("users")
    .select("first_name, last_name")
    .eq("id", uuid)
    .limit(1)
    .single();
  if (error) {
    console.log("An error occured: ");
    console.log(error);
    return;
  }
  return data;
};

export const getCurrentUserParams = async (selectParams) => {
  const userData = await getCurrentUserFromSupabase();
  if (userData === null) {
    console.log("There was an error");
    return null;
  }
  const { data, error } = await supabase
    .from("users")
    .select(selectParams)
    .eq("id", uuid)
    .limit(1)
    .single();
  if (error) {
    console.log("An error occured: ");
    console.log(error);
    return;
  }
  return data;
};

export const getCurrentUsersPayments = async () => {
  const userData = await getCurrentUserFromSupabase();
  if (userData === null) {
    console.log("There was an error");
    return null;
  }
  const { data, error } = await supabase
    .from("payments")
    .select("*")
    .eq("paid_for_user_id", uuid);
  if (error) {
    console.log("An error occured: ");
    console.log(error);
    return;
  }
  return data;
};

export const getIdsFromCurrentUserByParentId = async () => {
  const userData = await getCurrentUserFromSupabase();
  if (userData === null) {
    console.log("There was an error");
    return null;
  }
  const { data, error } = await supabase
    .from("users")
    .select("id")
    .eq("parent_id", uuid);
  if (error) {
    console.log("An error occured: ");
    console.log(error);
    return;
  }

  if (data.length === 0) return [];

  const arrayId = [];
  data.map((d) => {
    arrayId.push(d.id);
  });
  return arrayId;
};

export const getUsersFromCurrentUserByParentId = async () => {
  const userData = await getCurrentUserFromSupabase();
  if (userData === null) {
    console.log("There was an error");
    return null;
  }
  const { data, error } = await supabase
    .from("users")
    .select(
      "id,first_name, last_name, pricing (name, price), is_registered, registered_at"
    )
    .eq("parent_id", userData.id);
  if (error) {
    console.log("An error occured: ");
    console.log(error);
    return;
  }
  return data;
};
