import React from "react";
import "@styles/section.css";

export default function Section({
  children,
  title,
  subtitle,
  color,
  backgroundColor,
  className,
  colorMode
}) {
  if (!backgroundColor) backgroundColor = "none";
  if(colorMode === 'dark') {
    color = 'white';
    backgroundColor = '#333';
  } else if (colorMode === 'light') {
    color = 'black';
    backgroundColor = 'white';
  }


  // Inline styles to dynamically set the color of text elements
  const titleStyle = { color: color };
  const subtitleStyle = { color: color };
  const classStying = className ? className : "";

  return (
    <section className={'section ' + classStying} style={{ backgroundColor: backgroundColor }}>
      <h2 className='section-text section-h2 text-title-responsive' style={titleStyle}>{title}</h2>
      <h3 className='section-subtitle section-h2' style={subtitleStyle}>{subtitle}</h3>
      <div className='section-div'>{children}</div>
    </section>
  );
}
