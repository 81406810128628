import React, { useEffect, useState } from "react";
import supabase from "../../config/supabase";
import { Button } from "primereact/button";
import { ConfirmDialog, confirmDialog } from "primereact/confirmdialog";
import { Toast } from "primereact/toast";
import { useRef } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Divider } from "primereact/divider";
import { useNavigate } from "react-router-dom";
import { SelectUserData } from "../../config/DataHandler";
import { getTotalAddCamperAllowed } from "@utils/globalVariables.js";
import SelectDays from "../form/SelectDays.js";
import SelectSize from "../form/SelectSize.js";
import SelectTransport from "../form/SelectTransport.js";
import EditButton from "../buttons/EditButton.js";
import { calculateAgeByDate } from "../../utils/getAge.js";
import { getAdminSettingsBool } from "@db/admin.js";
import { LoadingSpinner } from "@components/status/Loading.js";

export default function AddCamperTab() {
  const maxCamper = getTotalAddCamperAllowed();
  const [camperData, setCamperData] = useState([]);
  const toast = useRef(null);
  const [userUuid, setUserUuid] = useState(null);
  const [amountOfcampers, setAmountOfcampers] = useState(0);
  const [disableNewUser, setDisableNewUser] = useState(true);
  const [loading, setLoading] = useState(true);
  const navFormAdd = useNavigate();

  const countryCodeConverter = new Intl.DisplayNames(["en"], {
    type: "region",
  });

  const getCamperData = async (id) => {
    const { data } = await supabase.from("users").select().eq("parent_id", id);
    if (data !== undefined && data.length > 0 && data !== null) {
      setCamperData(data);
      setAmountOfcampers(data.length);
    }
  };
  useEffect(() => {
    localStorage.setItem("activeTab", 2);
    const fetch = async () => {
      let data = await SelectUserData();
      const disableNewUser = await getAdminSettingsBool("DISABLE_NEW_USER");
      setDisableNewUser(disableNewUser);
      setUserUuid(data.id);
      getCamperData(data.id);
      setTimeout(() => setLoading(false), 1000);
    };
    fetch();
  }, []);

  const confirmDelete = (camper) => {
    confirmDialog({
      message:
        "Are you sure you want to delete " +
        camper.first_name +
        " " +
        camper.last_name +
        " from you list?",
      header: "Delete Confirmation",
      icon: "pi pi-info-circle",
      acceptClassName: "p-button-danger",
      accept: () => accept(camper.id),
    });
  };

  const displayCamper = () => {
    return (
      <>
        <Accordion>
          {camperData.map((camper, index) => {
            const age = camper.birthday
              ? calculateAgeByDate(camper.birthday)
              : 0;
            return (
              <AccordionTab
                key={index}
                header={camper.first_name + " " + camper.last_name}
              >
                <div className='row-profile'>
                  <p className='profile-info'>
                    <b>Full Name: </b>{" "}
                    {camper.first_name + " " + camper.last_name}
                  </p>
                </div>
                <Divider />
                <div className='row-profile'>
                  <p className='profile-info'>
                    <b>Birthday: </b> {camper.birthday}
                  </p>
                </div>
                <Divider />
                <div className='row-profile'>
                  <p>
                    <b>Country: </b> {countryCodeConverter.of(camper.country)}
                  </p>
                </div>
                <Divider />
                <div className='row-profile'>
                  <p>
                    <b>Church Mission: </b> {camper.church_mission}
                  </p>
                </div>
                <Divider />
                <div className='row-profile'>
                  <p>
                    <b>Member: </b> {camper.member ? "Yes" : "No"}
                  </p>
                </div>
                <Divider />
                <div className='row-profile'>
                  <p>
                    <b>Shirt size: </b> {camper.size}
                  </p>
                  <EditButton
                    reload={true}
                    uuid={camper.id}
                    updateData={{ size: null }}
                  >
                    <SelectSize edit age={age} />
                  </EditButton>
                </div>

                <Divider />
                <div className='row-profile'>
                  <p>
                    <b>Arrival Transportation: </b>
                    {camper.arrival_transportation}
                  </p>
                  <EditButton
                    reload={true}
                    uuid={camper.id}
                    updateData={{ arrival_transportation: null }}
                  >
                    <SelectTransport />
                  </EditButton>
                </div>
                <Divider />
                <div className='row-profile'>
                  <p>
                    <b>Departure Transportation: </b>{" "}
                    {camper.departure_transportation}
                  </p>
                  <EditButton
                    reload={true}
                    uuid={camper.id}
                    updateData={{ departure_transportation: null }}
                  >
                    <SelectTransport />
                  </EditButton>
                </div>
                <Divider />
                <div className='row-profile'>
                  <p>
                    <b>Attending camp days:</b>{" "}
                    {camper.dates.length === 5
                      ? "All days"
                      : camper.dates
                          .map((date, index) => {
                            return index === camper.dates.length - 1
                              ? date
                              : date + ", ";
                          })
                          .join("")}
                  </p>
                  <EditButton
                    reload={true}
                    uuid={camper.id}
                    updateData={{ dates: null }}
                  >
                    <SelectDays />
                  </EditButton>
                </div>
                <Divider />
                <div className='row-profile'>
                  <p>
                    <b>Accommodation: </b> {camper.accommodation}
                  </p>
                </div>
                <Divider />
                <p>
                  <b>Uuid: </b> {camper.id}
                </p>
                <Divider />
              </AccordionTab>
            );
          })}
        </Accordion>
      </>
    );
  };

  const accept = async (id) => {
    const { error } = await supabase.from("users").delete().eq("id", id);
    if (error) {
      console.log("error", error);
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Error: " + error.message,
        life: 5000,
      });
      return;
    }
    toast.current.show({
      severity: "success",
      summary: "Success",
      detail: "Successfully deleted",
      life: 5000,
    });
    window.location.reload();
    getCamperData(userUuid);
  };
  useEffect(() => {
    displayButton();
  }, [amountOfcampers]);

  const displayButton = () => {
    return (
      <>
        <div className='center'>
          <Button
            className='prime'
            label='Add Camper'
            severity='info'
            icon='pi pi-user-plus'
            iconPos='left'
            disabled={amountOfcampers >= maxCamper || disableNewUser}
            onClick={() => {
              localStorage.setItem("activeTab", 2);
              navFormAdd("/addCamper");
            }}
          />
        </div>
      </>
    );
  };

  return (
    <>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          {disableNewUser && (
            <p className='tag-red normal-text'>
              Sign up for new campers is disabled.
            </p>
          )}
          <p className='m-0'>
            Add a person if you need to manage their campers registration. You
            can add up to 7 people.
          </p>
          <div className='grid-center' style={{ marginTop: "10px" }}>
            {camperData.length <= 0 ? (
              <p>Your list is currently empty</p>
            ) : (
              displayCamper()
            )}
            {amountOfcampers >= maxCamper ? (
              <>
                {displayButton()}
                <p>Max number of campers reached</p>
              </>
            ) : (
              displayButton()
            )}
          </div>
        </>
      )}
    </>
  );
}
