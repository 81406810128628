import { useState } from "react";
import { MultiSelect } from "primereact/multiselect";

export default function SelectDays({ onSelectChange }) {
  const [days, setDays] = useState("");

  const handleSelectChange = (e) => {
    setDays(e.value);
    onSelectChange(e.value); // Call the callback function with the selected value
  };

  const dates = [
    { name: "Wednesday - May 28", value: "Wednesday" },
    { name: "Thursday - May 29", value: "Thursday" },
    { name: "Friday - May 30", value: "Friday" },
    { name: "Saturday - May 31", value: "Saturday" },
    { name: "Sunday - June 1", value: "Sunday" },
  ];

  //https://stackoverflow.com/questions/74940958/add-a-title-beside-multiselect-selectall-checkbox-in-prime-react
  //https://codesandbox.io/p/sandbox/weathered-fast-e5jsgj?file=%2Fsrc%2Fdemo%2FMultiSelectDemo.js%3A159%2C1-164%2C5
  const onShow = () => {
    let selectAllCheckbox = document.querySelector(
      ".p-multiselect-header > .p-checkbox"
    );

    // Create a span element for the text
    let selectAllText = document.createElement("span");
    selectAllText.textContent = " Select all days";
    selectAllText.style.paddingLeft = "5px"; // Adjust padding as needed

    // Insert the text after the checkbox
    selectAllCheckbox.after(selectAllText);
  };
  return (
    <MultiSelect
      value={days}
      options={dates}
      onChange={handleSelectChange}
      optionLabel='name'
      placeholder="Select the days you're attending"
      showSelectAll={true}
      selectAll={true}
      onShow={onShow}
    />
  );
}
