import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Tag } from "primereact/tag";
import supabase from "@config/supabase";
import { InputText } from "primereact/inputtext";
import "@styles/dataList.css";
import "/node_modules/flag-icons/css/flag-icons.min.css";
import { FilterMatchMode } from "primereact/api";
import { registerTag } from "@components/rows/tags.js";
import { countryCodeToName } from "@utils/countryUtils.js";
import PieChart from "@components/charts/PieChart";

import "@styles/tags.css";
import "@styles/flags.css";

export default function CampersList() {
  const [campers, setCamper] = useState([]);
  const [count, setCount] = useState({});
  const [dataChart, setDataChart] = useState([]); // [[label, value, color], [label, value, color], [label, value, color]]
  const usersArray = [];

  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });
  // Effect to handle window resize
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width and height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  let runOnce = true;

  useEffect(() => {
    const fetchCampersData = async () => {
      if (!runOnce) {
        return;
      }
      runOnce = false;
      const { data, error } = await supabase.from("users").select();
      // Filter registered and unregistered campers
      const registeredUsers = data.filter((user) => user.is_registered);
      const unregisteredUsers = data.filter((user) => !user.is_registered);

      // Set count for registered and total users
      setCount({
        registered: registeredUsers.length,
        unregistered: unregisteredUsers.length,
        total: registeredUsers.length + unregisteredUsers.length,
      });

      setDataChart([
        {
          label: "Registered",
          value: registeredUsers.length,
          color: "#24C86A",
          hover: "#3AEA85",
        },
        {
          label: "Not registered",
          value: unregisteredUsers.length,
          color: "#F51342",
          hover: "#FF446B",
        },
      ]);

      if (error) {
        console.log("error", error);
        return;
      } else {
        setDataToArray(data);
      }
    };
    const fetchUserStatus = async () => {
      // const notRegistered = await countUsersByRegistrationStatus(false);
      // const registered = await countUsersByRegistrationStatus(true);
    };
    fetchUserStatus();
    fetchCampersData();
  }, []);

  const setDataToArray = (data) => {
    data.forEach((user) => {
      createArrayUser(user);
    });

    let setArrayToCampers = [];
    for (let i = 0; i < usersArray.length; i++) {
      setArrayToCampers.push(usersArray[i][0]);
    }
    setCamper(setArrayToCampers);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const createArrayUser = (c) => {
    const user = [
      {
        id: c.id,
        fullName: c.first_name + " " + c.last_name,
        church: c.church_mission,
        countryId: c.country.toLowerCase(),
        country: countryCodeToName(c.country),
        is_registered: c.is_registered ? "Yes" : "No",
        created_at: c.created_at,
      },
    ];
    usersArray.push(user);
  };

  const countryFlag = (rowData) => {
    return (
      <>
        {/* <span className={`fi fi-${rowData.countryId}`}> </span> */}
        <span className={`flag flag-${rowData.countryId}`}> </span>
        <span style={{ marginLeft: "10px" }}>{rowData.country}</span>
      </>
    );
  };

  const colorTag = (team) => {
    switch (team) {
      case "Blue": {
        return "tag-blue";
      }
      case "Red": {
        return "tag-red";
      }
      case "Green": {
        return "tag-green";
      }
      case "Black": {
        return "tag-black";
      }
      case "White": {
        return "tag-white";
      }
      default: {
        return "tag-not-assigned";
      }
    }
  };

  const teamTag = (rowData) => {
    return (
      <Tag
        //Team public view disabled
        // value={rowData.team}
        // className={"tag-style " + colorTag(rowData.team)}
        value='Not Assigned'
        className={"tag-style " + colorTag("")}
      ></Tag>
    );
  };

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    name: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    "country.name": { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    representative: { value: null, matchMode: FilterMatchMode.IN },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
    verified: { value: null, matchMode: FilterMatchMode.EQUALS },
  });
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [loading, setLoading] = useState(true);
  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const renderHeader = () => {
    return (
      <>
        <div className='flex justify-content-end'>
          <span className='p-input-icon-left'>
            <i className='pi pi-search' />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder='Search Camper'
            />
          </span>
        </div>
      </>
    );
  };

  return (
    <>
      <div className='center-grid width-100'>
        <PieChart dataObject={dataChart} />
        <br />
        <div className='box-flex-wrap gap-20'>
          <p className='text-black text-center tag-green'>
            Registered: {count.registered} <b></b>
          </p>
          <p className='text-black text-center tag-red'>
            Not registered: {count.unregistered}
          </p>
          <p className='text-black text-center tag-blue'>
            Total: {count.total}
          </p>
        </div>
      </div>
      <DataTable
        value={campers}
        header={renderHeader()}
        selectionMode='single'
        showGridlines
        stripedRows
        loading={loading}
        filters={filters}
        scrollable={windowSize.width > 700 ? true : false}
        // scrollable

        filterDisplay='row'
        scrollHeight={windowSize.width > 700 ? "80vh" : false}
        // scrollHeight='80vh'
        globalFilterFields={["fullName"]}
        sortField='created_at'
        sortOrder={-1}
        removableSort
        size='medium'
      >
        <Column field='fullName' header='Name' sortable></Column>
        <Column
          field='country'
          header='Country'
          body={countryFlag}
          sortable
        ></Column>
        <Column field='church' header='Church/Mission' sortable></Column>
        <Column
          field='team'
          header='Team'
          body={teamTag}
          hidden
          sortable
        ></Column>
        <Column
          field='is_registered'
          header='Status'
          sortable
          body={registerTag}
        ></Column>
        <Column
          field='created_at'
          header='Created'
          sortable
          hidden={true}
        ></Column>
      </DataTable>
    </>
  );
}
