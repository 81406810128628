import React from "react";
import "@styles/footer.css";
import facebookLogo from "@img/icons/facebook.png";
import instagramLogo from "@img/icons/instagram.png";
import youtubeLogo from "@img/icons/youtube.png";
import homeLoge from "@img/icons/website.png";
import { useNavigate } from "react-router-dom";

export default function Footer() {
  const year = new Date().getFullYear();
  const nav = useNavigate();
  return (
    <footer>
      <section className='box'>
        <div className='box-container'>
          <h2>Contact info</h2>
          <ul className="list-space">
            <li>
              Church email:
              <a href='mailto: camp.cbmbc@gmail.com'> cbmbchurch@gmail.com</a>
            </li>
            <li>
              Camp email:
              <a href='mailto: camp.cbmbc@gmail.com'> camp.cbmbc@gmail.com</a>
            </li>
            <li>Phone number: +45 31238926</li>
            <li>CVR: 29998248</li>
          </ul>
        </div>
        <div className='box-container'>
          <h2>Reference link</h2>
          <ul>
            <li>
              Copenhagen Bethel Missionary Baptist Church
              <div className='social-ref'>
                <a href='https://www.facebook.com/cbmbchurch/' target='blank'>
                  <img src={facebookLogo} alt='facebook page cbmbc' />
                </a>
                <a href='https://www.instagram.com/cbmbchurch/' target='blank'>
                  <img src={instagramLogo} alt='cbmbc instragram page ' />
                </a>
                <a href='https://www.youtube.com/@cbmbchurch' target='blank'>
                  <img src={youtubeLogo} alt='cbmbc youtube page' />
                </a>
                <a href='https://cbmbc.org' target='blank'>
                  <img src={homeLoge} alt='cbmbc website' />
                </a>
              </div>
            </li>
          </ul>
          <ul>
            <li>
              Malmö Bethel Missionary Baptist Church
              <div className='social-ref'>
                <a href='https://www.facebook.com/malmobaptists' target='blank'>
                  <img src={facebookLogo} alt='facebook page malmobaptists' />
                </a>
                <a href='https://www.instagram.com/mbmbchurch/' target='blank'>
                  <img src={instagramLogo} alt='mbmbc instragram page ' />
                </a>
              </div>
            </li>
          </ul>
          <ul>
            <li>
              Barcelona Bethel Missionary Baptist Mission
              <ul className='social-ref'>
                <a
                  href='https://www.facebook.com/p/Barcelona-Bethel-Missionary-Baptist-Mission-Church-100064713667448/'
                  target='blank'
                >
                  <img src={facebookLogo} alt='facebook page bbmbc' />
                </a>
                <a
                  href='https://barcelonabethelmissionarybaptistchurch.wordpress.com/'
                  target='blank'
                >
                  <img src={homeLoge} alt='bbmbc website' />
                </a>
              </ul>
            </li>
          </ul>
          <ul>
            <li>
              Netherlands Bethel Missionary Baptist Mission
              <ul className='social-ref'>
                <a href='https://www.facebook.com/NBMBM2018/'>
                  <img src={facebookLogo} alt='facebook page nbmbm' />
                </a>
              </ul>
            </li>
          </ul>
        </div>
        <div className='box-container'>
          <h2>Additional info </h2>
          <ul className="list-space">
            <a href='/group-formation'>
              <li>Group Formation Algorithm</li>
            </a>
            <a href='/terms-of-service'>
              <li>Terms of Service</li>
            </a>
            <a href='/privacy-policy'>
              <li>Privacy Policy</li>
            </a>
          </ul>
        </div>
      </section>
      <section className='copy-right'>
        <p>
          &copy; {year} Copenhagen Bethel Missionary Baptist Church
          <br />& Joshua James Calba All rights reserved.
        </p>
      </section>
    </footer>
  );
}
